import React from 'react';
import PropTypes from 'prop-types';
import H5 from 'components/H5'
import Modal from 'components/Modal';

export default class PrivacyPolicyModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        show: PropTypes.bool
    };

    render() {
        return (
            <Modal declineButtonOnClick={this.props.closeModal}
                   show={this.props.show}
                   title="My Wonderful Life Privacy Policy">
                <p><i>Effective Date: May 14, 2008</i></p>
                <p>This Privacy Policy discusses what information we collect at www.mywonderfullife.com, how we use it, and how we protect it.</p>

                <p>What information is collected?<br />
                    Personal information refers to specific facts used to identify you as an individual. This information includes your name, address, telephone number, email address, or other individually identifiable information.  We collect personal information at this site only when you supply it to us, such as when you create an account on the site.</p>

                <p>As you complete your own personal book, you may also provide additional information about yourself.  This information is not shared except with “Angels” that you designate to access your account.</p>

                <p>To learn what areas of our site are most popular, we collect anonymous, nonpersonal data about the pages you go to through the use of cookies--small files that are placed on your computer's hard drive.  Each cookie is automatically deleted after a period of time.</p>

                <p>We may share aggregated demographic information (data that cannot identify any individual person) with our partners and advertisers.</p>

                How is my information used or shared?
                When you submit personal information over our web site, the only people who are allowed to see it are those who need it in order to provide the information or service you requested. We may need to share your personal information to comply with legal, regulatory or accreditation requirements.

                <p>Specifically, we may use and disclose your personal information for the following purposes:</p>

                <p>(1)    To our employees and contractors who provide services to you;</p>

                <p>(2)    To organizations that perform services for us or on our behalf, including, without limitation, to provide customer service; to conduct research, marketing, or data processing; or to provide marketing and promotional services for the site, to the extent that information is necessary;</p>

                <p>(3)    To protect the site and our rights; to protect ourselves against liability or prevent fraudulent activity; or, where necessary, to permit us to pursue available remedies or limit any damages that we may sustain;</p>

                <p>(4)    In connection with the sale, merger, spin-off, or other corporate reorganization, where the information is provided to the new controlling entity in the regular course of business;</p>

                <p>(5)    If required to comply with a subpoena, warrant, or an order made by a court, person or body with jurisdiction to compel the production of information, or to comply with the rules or court relating to the production of records; and</p>

                <p>(6)    If we believe in good faith that a law, regulation, rule or guideline requires it.</p>

                <H5>How is my information protected?</H5>
                <p>We maintain a variety of physical, electronic and procedure safeguards designed to protect your personal information and prevent unauthorized access.  Your account information is password protected and secured using SSL (Secure Sockets Layer). The personal information that you enter onto the site is encrypted in our database for your protection.</p>

                <p>If we issue you a username and password, you agree to use your best efforts to prevent access to this site through your username and password by anyone other than yourself. If you provide access to other people, as “Angels” or otherwise, we are not responsible for providing access to those individuals if you later withdraw your permission. While we use administrative, procedural and technical safeguards to secure the site and any content that you submit to the site, we cannot guarantee that the site is completely secure.</p>


                <H5>External Links</H5>
                <p>This site may contain links to web sites operated by third parties. These links are provided solely for convenience and reference purposes only. The inclusion of any link does not imply endorsement by us of the content of any web site to which this web site provides a link, nor are we liable for your reliance on or use of any information or materials contained in them. This Privacy Policy applies only to this web site. Please be aware that we are not responsible for and has no control over the privacy policies and practices of third party sites. We encourage you to read the privacy statements of each and every web site that requests personal information from you.</p>

                <p>Changes to the Privacy Policy<br />
                    We may change or supplement this Privacy Policy from time to time and we will post revised versions of this Policy on the site.  Privacy Policy changes will apply to the information collected from the date we post the revised Privacy Policy to the site, as well as to existing information held by us.</p>

                <p>Contact Information<br />
                    All questions and concerns about your privacy can be sent to privacy@mywonderfullife.com or 310 4th Avenue South Minneapolis, MN 55415</p>
            </Modal>
        );
    }
}
