import './MainFooter.scss';
import React from 'react';
import A from 'components/A';
import Button from 'components/Button';
import PrivacyPolicyModal from 'components/Modal/PrivacyPolicyModal';
import TermsOfUseModal from 'components/Modal/TermsOfUseModal'

// const LEFT_LINKS = [
//     {
//         text: 'mywonderfullife.com',
//         href: 'https://mywonderfullife.com',
//     }, {
//         text: 'Resources',
//         href: 'https://mywonderfullife.com/blog',
//     }, {
//         text: 'Customer Support',
//         href: 'https://mywonderfullife.com/customer-service-faq',
//     }
// ];

export default class MainFooter extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            showPrivacyPolicyModal: false,
            showTermsOfUseModal: false
        };
    }

    render() {
        return (
            <footer className="c-main-footer">
                <div className="c-main-footer--inner">
                    <nav className="c-main-footer__left-nav">
                        <div className="c-main-footer__link-wrapper">
                            <A href="https://mywonderfullife.com" target="_blank">mywonderfullife.com</A>
                        </div>

                        <div className="c-main-footer__link-wrapper">
                            <A href="https://mywonderfullife.com/blog" target="_blank">Resources</A>
                        </div>

                        <div className="c-main-footer__link-wrapper">
                            <A href="https://mywonderfullife.com/customer-service-faq" target="_blank">Customer Support</A>
                        </div>
                    </nav>
                    <nav className="c-main-footer__right-nav">
                        <div className="c-main-footer__link-wrapper">
                            <Button theme="link" onClick={() => this.setState({showTermsOfUseModal: true})}>Terms of Service</Button>
                        </div>

                        <div className="c-main-footer__link-wrapper">
                            <Button theme="link" onClick={() => this.setState({showPrivacyPolicyModal: true})}>Privacy</Button>
                        </div>
                    </nav>
                    <div className="c-main-footer__copyright">
                        &copy; {new Date().getFullYear()} My Wonderful Life
                    </div>
                </div>

                <PrivacyPolicyModal
                    closeModal={() => this.setState({showPrivacyPolicyModal: false})}
                    show={this.state.showPrivacyPolicyModal}
                />

                <TermsOfUseModal
                    closeModal={() => this.setState({showTermsOfUseModal: false})}
                    show={this.state.showTermsOfUseModal}
                />
            </footer>
        );
    }
}
