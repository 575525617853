import React from 'react';
import PropTypes from 'prop-types';
import H5 from 'components/H5'
import Modal from 'components/Modal';

export default class TermsOfUseModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        show: PropTypes.bool
    };

    render() {
        return (
            <Modal declineButtonOnClick={this.props.closeModal}
                   show={this.props.show}
                   title="My Wonderful Life Terms of Use">
                <p>By using this site, you signify your agreement to these terms and conditions. If you do not agree to this Agreement, do not use this site. Please check this Agreement periodically for changes as we reserve the right to revise this Agreement and your continued use of this site following the posting of any changes to the Agreement constitutes acceptance of such changes.</p>

                <H5>License to Use Site</H5>
                <p>We grant you a non-exclusive, non-transferable limited license to use this site in accordance with the terms and conditions in this Agreement. We reserve the right to modify or discontinue any service or a portion or attribute thereof, or the offering of any information, good, content, product or service with or without notice to any user.</p>

                <H5>Limitations of Site</H5>
                <p>The wishes that you express on the site do not create binding obligations on anyone, including My Wonderful Life LLC or any other third parties identified by you in your content. This site is not intended to replace the necessity of having a will or other estate plan and healthcare documents that reflect your intentions and are binding under the laws or your state. This site does not provide legal advice and we recommend that you seek legal counsel regarding your estate planning matters.</p>

                <H5>Site Ownership</H5>
                <p>Certain content and services available on this site are the property of us or our advertisers and licensors, and are protected by copyrights, moral rights, trademarks, service marks, patents, trade secrets, and other proprietary rights and laws internationally. All rights not expressly granted herein are fully reserved by us.</p>

                <H5>Content Ownership</H5>
                <p>Any content that you submit to the site, including text, communications, video, software, images, sounds, data, or other information, remains your property and responsibility. Upon submission of content to the site, you grant us the rights to post the content on the site and grant access to the submitted content in accordance with the instructions on this site.</p>

                <H5>Restrictions on Use</H5>
                <p>Except as may be explicitly permitted through this site, you will not save, download, cut and paste, sell, license, rent, lease, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit, or create derivative works from materials, code or content on or from this site. Systematic retrieval of data or other content from this site to create or compile, directly or indirectly, a collection, compilation, database or directory without written permission from us is prohibited. In addition, use of the content or materials for any purpose not expressly permitted in this Agreement is prohibited.</p>

                <p>You agree that you shall not try to reverse engineer, reverse assemble, reverse compile, de-compile, disassemble, translate or otherwise alter any executable code, contents or materials on or received via this site. You understand that such actions are likely to subject you to serious civil and criminal legal penalties and that we shall pursue such penalties to the full extent of the law to protect its rights and the rights of its other licensors.</p>

                <p>You also agree that you shall not harvest or collect information about the users of this site or use such information for the purpose of transmitting or facilitating transmission of unsolicited bulk electronic e-mail or communications for any other commercial purpose of your own or a third party.</p>

                <H5>Security </H5>
                <p>If we issue you a username and password, you agree to use your best efforts to prevent access to this site through your username and password by anyone other than yourself. If you provide access to other people, as “Angels” or otherwise, we are not responsible for providing access to those individuals if you later withdraw your permission. While we use administrative, procedural and technical safeguards to secure the site and any content that you submit to the site, we cannot guarantee that the site is completely secure.</p>

                <H5>Terms of Submissions</H5>
                <p>By using features of this site that allow you to post or otherwise transmit information to or through this site, or which may be seen by other users, you agree that you shall not upload, post, or otherwise distribute or facilitate distribution of any content, including text, communications, video, software, images, sounds, data, or other information, that:</p>
                <p>(a) Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, sexually explicit or graphic, or otherwise in violation of this site's rules or policies; (b) Victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability; (c) Infringes any patent, trade mark, service mark, trade secret, copyright, moral right, right of publicity, privacy or other proprietary right of any party; (d) Constitutes unauthorized or unsolicited advertising, junk or bulk e-mail, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling; (e) Contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party; or (f) Impersonates any person or entity, including any employee or representative of this site, its licensors or advertisers.</p>

                <p>This site generally does not pre-screen, monitor, or edit the content posted by users of this site. However, this site and its agents have the right, at their sole discretion, to remove any content that, in this site's sole judgment, does not comply with the Terms of Submissions or is otherwise harmful, objectionable, or inaccurate. This site is not liable for any failure, delay, damages or results, in removing such content.</p>

                <H5>Disclaimer of Warranties</H5>
                <p>WE AND OUR ADVERTISERS AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THIS SITE, THE SUITABILITY OF THE INFORMATION CONTAINED ON OR RECEIVED THROUGH USE OF THIS SITE, OR ANY SERVICES OR PRODUCTS RECEIVED THROUGH THIS SITE. ALL INFORMATION AND USE OF THIS SITE ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. WE HEREBY DISCLAIM ALL WARRANTIES WITH REGARD TO THIS SITE, THE INFORMATION CONTAINED ON OR RECEIVED THROUGH USE OF THIS SITE AND ANY SERVICES OR PRODUCTS RECEIVED THROUGH THIS SITE, INCLUDING ALL EXPRESS, STATUTORY, AND IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE CONTENTS OR ANY INFORMATION RECEIVE THROUGH THIS SITE ARE ACCURATE, RELIABLE OR CORRECT; THAT THIS SITE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE CONTENTS OR ANY INFORMATION RECEIVED THROUGH THIS SITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOUR USE OF THIS SITE IS SOLELY AT YOUR RISK. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF CERTAIN WARRANTIES, THESE EXCLUSIONS MAY NOT APPLY TO YOU BUT SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW OF YOUR JURISDICTION.</p>

                <H5>Limitation of Liability</H5>
                <p>UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES THAT RESULT FROM, OR ARISE OUT OF THE USE OF, OR INABILITY TO USE, THIS SITE, THE INFORMATION CONTAINED ON OR RECEIVED THROUGH USE OF THIS SITE, OR ANY SERVICES OR PRODUCTS RECEIVED THROUGH THIS SITE. THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, OUR LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW OF YOUR JURISDICTION.</p>

                <H5>Indemnification</H5>
                <p>You agree to defend, indemnify, and hold harmless My Wonderful Life, LLC, its advertisers, licensors, subsidiaries and other affiliated companies, and our and their employees, contractors, officers, agents and directors from all liabilities, claims, and expenses, including attorney's fees, that arise from your use of this site, or any services, information or products from this site, or any violation of this Agreement. We reserve the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you shall cooperate in asserting any available defenses.</p>

                <H5>Errors and Delays</H5>
                <p>We are not responsible for any errors or delays in responding, forwarding or providing information caused by an incorrect e-mail address provided by you or other technical problems beyond our reasonable control.</p>

                <H5>Dispute Resolution</H5>
                <p>Any claim or controversy arising out of or relating to the goods or services provided by us, or to any acts or omissions for which you may contend we are liable, including but not limited to any claim or controversy, must be brought in the state or federal courts located in Minneapolis, Minnesota.</p>

                <H5>Other Terms</H5>
                <p>This Agreement shall be subject to and construed in accordance with the laws of the State of Minnesota, excluding its conflict of laws principles. If any part of these Agreement are determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid enforceable provision that most closely matches the intent of the original provision, and the remainder of the Agreement shall continue in effect. A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>

                <small>GP:2299263 v1</small>
            </Modal>
        );
    }
}
