import Request from 'utils/request';
import PATHS from 'utils/paths';

import * as types from './constants';

const changeRoleStart = () => ({
    type: types.CHANGE_ROLE_START
});

const changeRoleSuccess = (data) => ({
    type: types.CHANGE_ROLE_SUCCESS,
    data
});

const changeRoleFailure = (error) => ({
    type: types.CHANGE_ROLE_FAILURE,
    error
});

const getCollaboratorsStart = () => ({
    type: types.GET_COLLABORATORS_START
});

const getCollaboratorsSuccess = (data) => ({
    type: types.GET_COLLABORATORS_SUCCESS,
    data
});

const getCollaboratorsFailure = (error) => ({
    type: types.GET_COLLABORATORS_FAILURE,
    error
});

const removeCollaboratorsStart = () => ({
    type: types.REMOVE_COLLABORATORS_START
});

const removeCollaboratorsSuccess = (data) => ({
    type: types.REMOVE_COLLABORATORS_SUCCESS,
    data
});

const removeCollaboratorsFailure = (error) => ({
    type: types.REMOVE_COLLABORATORS_FAILURE,
    error
});

const sendInvitationStart = () => ({
    type: types.SEND_INVITATION_START
});

const sendInvitationSuccess = (data) => ({
    type: types.SEND_INVITATION_SUCCESS,
    data
});

const sendInvitationFailure = (error) => ({
    type: types.SEND_INVITATION_FAILURE,
    error
});

export const setCollaborators = (data) => ({
    type: types.SET_COLLABORATORS,
    data
});

export const setInvitationData = (data) => ({
    type: types.SET_INVITATION_DATA,
    data
});

export const showHideModal = (data) => ({
    type: types.SHOW_HIDE_MODAL,
    data
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const changeRole = (role, collaborator) => (dispatch, getStore) => {
    dispatch(changeRoleStart());

    const roleRequest = new Request(getStore().auth.token);
    const accountId = getStore().auth.account.id;

    return roleRequest.put(PATHS.collaborators.changeRole(accountId, collaborator.id), {role: role.value})
        .then((response) => {
            // console.log(response);
            collaborator.role = role.value;
            dispatch(changeRoleSuccess(collaborator));
            return response;
        })
        .catch((err) => {
            // console.log('change role error', err, err.response);
            err.friendlyMessage = 'Error changing role. Please try again.';
            dispatch(changeRoleFailure(err));
            throw err;
        });
}

export const getCollaborators = () => (dispatch, getStore) => {
    dispatch(getCollaboratorsStart());

    let accountId = getStore().auth.account.id;

    const collaboratorsRequest = new Request(getStore().auth.token);

    return collaboratorsRequest.get(PATHS.collaborators.getCollaborators(accountId))
        .then((response) => {
            let collaborators = [],
                owner;
            response.data.data.users.forEach((u) => {
                if(u.isAccountOwner) {
                    owner = u;
                } else {
                    collaborators.push(u);
                }
            });

            response.data.data.invitations.forEach(i => i.isInvitation = true);
            collaborators = collaborators.concat(response.data.data.invitations);
            collaborators = collaborators.sort((a, b) => {
                if(a.firstName < b.firstName) { return -1; }
                if(a.firstName > b.firstName) { return 1; }
                return 0;
            });
            if(owner) {
                collaborators.unshift(owner);
            }
            dispatch(getCollaboratorsSuccess(collaborators));
            return response;
        })
        .catch((err) => {
            // console.log('getCollaborators error', err, err.response);
            err.friendlyMessage = 'Error getting collaborators. Please try again.';
            dispatch(getCollaboratorsFailure(err));
            throw err;
        });
};

export const removeCollaborators = (ids) => (dispatch, getStore) => {
    dispatch(removeCollaboratorsStart());

    let accountId = getStore().auth.account.id;
    let collaboratorsRequest = new Request(getStore().auth.token);

    let promises = ids.map((item) => {
        let id = item.replace('c', '').replace('i', '');

        if(item.indexOf('c') === -1) {
            return collaboratorsRequest.delete(PATHS.collaborators.deleteInvitation(accountId, id))
        } else {
            return collaboratorsRequest.delete(PATHS.collaborators.deleteCollaborators(accountId, id));
        }
    });

    return Promise.all(promises)
        .then((res) => {
            dispatch(removeCollaboratorsSuccess());
            dispatch(getCollaborators());
            return res;
        })
        .catch((err) => {
            // console.log('removeCollaborators error', err, err.response);
            err.friendlyMessage = 'Error removing collaborators. Please try again.';
            dispatch(removeCollaboratorsFailure(err));
            throw err;
        })
};

export const sendInvitation = () => (dispatch, getStore) => {
    dispatch(sendInvitationStart());

    let accountId = getStore().auth.account.id;
    let invitationData = getStore().collaborators.invitationData;

    const collaboratorsRequest = new Request(getStore().auth.token);

    return collaboratorsRequest.post(PATHS.collaborators.sendInvitation(accountId), invitationData)
        .then((response) => {
            dispatch(sendInvitationSuccess(response.data.data));
            dispatch(getCollaborators());
            return response;
        })
        .catch((err) => {
            // console.log('sendInvitation error', err, err.response);
            err.friendlyMessage = 'Error sending invitation. Please try again.';
            dispatch(sendInvitationFailure(err));
            throw err;
        });
};
