import React from 'react';
import PropTypes from 'prop-types';
import { validated } from "react-custom-validation";
import update from 'immutability-helper';
import Dropzone from "react-dropzone";
import { connect } from "react-redux";

import { handleTextChange } from 'utils/handle-changes';
import { isEmail, isRequired } from 'utils/validations';

import { getLoggedInUser } from "../Auth/store/actions";
import { uploadFiles } from "../Upload/actions";

import Button from 'components/Button';
import Form from 'components/Form';
import FormColumn from 'components/FormColumn';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import H4 from 'components/H4';
import Label from 'components/Label';
import Modal from 'components/Modal';
import ProgressBar from 'components/ProgressBar';
import Textbox from 'components/Textbox';

class ProfileForm extends React.Component {
    static propTypes = {
        error: PropTypes.object,
        isSavingProfile: PropTypes.bool,
        openChangePasswordModal: PropTypes.func.isRequired,
        profile: PropTypes.object.isRequired,
        saveProfile: PropTypes.func.isRequired,
        setProfile: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            activeDrag: false,
            avatarPreview: null,
            showSuccessModal: false
        }
    };

    handleChange = (name, value) => {
        let newProfileObj = update(this.props.profile, {});
        newProfileObj[name] = value;
        this.props.setProfile(newProfileObj);
    };

    onFileSelect = (files) => {
        // console.log(files);

        files[0].preview = URL.createObjectURL(files[0])

        this.setState({activeDrag: false, avatarPreview: files[0]});

        this.props.uploadFiles(files, 'profilePhoto')
            .then((res) => {
                // console.log('res', res);
                this.handleChange('profilePicStorageKey', res.files[0].storageKey);
            })
    };

    handleSubmit = (isValid) => {
        if(isValid) {
            this.props.saveProfile()
                .then((res) => {
                    this.setState({avatarPreview: null});
                });
        }
    };

    render() {
        let { error, isSavingProfile, profile, $field, $validation } = this.props;
        let { profilePhotoIsUploading, profilePhotoUploadPercentage } = this.props.upload;

        return (
            <Form className="profile-form">
                <div className="profile-form__section">
                    <div className="profile-form__right">
                        <Dropzone accept="image/*"
                                  multiple={false}
                                  name="avatar"
                                  onDragEnter={() => this.setState({activeDrag: true})}
                                  onDragLeave={() => this.setState({activeDrag: false})}
                                  onDrop={this.onFileSelect}>
                            {({getRootProps, getInputProps}) => (
                                <div className="profile-form__avatar-upload-content" {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <div
                                        className="profile-form__avatar"
                                        style={{backgroundImage: `url(${this.state.avatarPreview ? this.state.avatarPreview.preview : profile.profilePicUrl})`}}
                                    />

                                    <Button theme="secondary">
                                        Select Photo
                                    </Button>

                                    {profilePhotoIsUploading ? (
                                        <div className="profile-form__avatar-upload-content-progress-bar-wrapper">
                                            <ProgressBar
                                                showCounter
                                                percentComplete={profilePhotoUploadPercentage}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </Dropzone>
                    </div>

                    <div className="profile-form__left">
                        <FormRow>
                            <Textbox
                                id="txtFirstName"
                                label="First Name"
                                name="firstName"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                required
                                type="text"
                                validation={$validation.firstName}
                                value={profile.firstName}
                                {...$field('firstName', (event) => handleTextChange(this.handleChange, event))}
                            />
                        </FormRow>

                        <FormRow>
                            <Textbox
                                id="txtLastName"
                                label="Last Name"
                                name="lastName"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                required
                                type="text"
                                validation={$validation.lastName}
                                value={profile.lastName}
                                {...$field('lastName', (event) => handleTextChange(this.handleChange, event))}
                            />
                        </FormRow>

                        <FormRow>
                            <Textbox
                                id="txtEmail"
                                label="Email"
                                name="email"
                                required
                                type="text"
                                validation={$validation.email}
                                value={profile.email}
                                {...$field('email', (event) => handleTextChange(this.handleChange, event))}
                            />
                        </FormRow>

                        <FormRow>
                            <Button onClick={this.props.openChangePasswordModal} theme="secondary">
                                Change Password
                            </Button>
                        </FormRow>
                    </div>
                </div>

                <div className="profile-form__section">
                    <H4>
                        Additional Information
                    </H4>

                    <FormRow>
                        <Textbox
                            id="txtPhone"
                            label="Phone Number"
                            name="phone"
                            onChange={(event) => handleTextChange(this.handleChange, event)}
                            type="text"
                            value={profile.phone || ''}
                        />
                    </FormRow>


                    <FormRow columnCount={2}>
                        <Label>
                            Address
                        </Label>
                        <FormColumn>
                            <Textbox
                                id="txtAddress"
                                label="Street"
                                name="address"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                smallLabel
                                type="text"
                                value={profile.address || ''}
                            />
                        </FormColumn>
                        <FormColumn>
                            <Textbox
                                id="txtAddress2"
                                label="Street 2"
                                name="address2"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                smallLabel
                                type="text"
                                value={profile.address2 || ''}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow columnCount={3}>
                        <FormColumn>
                            <Textbox
                                id="txtCity"
                                label="City"
                                name="city"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                smallLabel
                                type="text"
                                value={profile.city || ''}
                            />
                        </FormColumn>
                        <FormColumn>
                            <Textbox
                                id="txtState"
                                label="State"
                                name="state"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                smallLabel
                                type="text"
                                value={profile.state || ''}
                            />
                        </FormColumn>
                        <FormColumn>
                            <Textbox
                                id="txtZip"
                                label="Zip"
                                name="zip"
                                onChange={(event) => handleTextChange(this.handleChange, event)}
                                smallLabel
                                type="text"
                                value={profile.zip || ''}
                            />
                        </FormColumn>
                    </FormRow>

                    {error && Object.keys(error).length > 0 ? (
                        <FormRow className="error-message">
                            <FormValidationMessage>
                                {error.friendlyMessage || error.message}
                            </FormValidationMessage>
                        </FormRow>
                    ) : null}

                    <FormRow>
                        <Button disabled={isSavingProfile}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                                }}
                                showActivityIndicator={isSavingProfile}
                                type="submit">
                            Save Changes
                        </Button>
                    </FormRow>
                </div>

                <Modal confirmButtonOnClick={() => this.setState({showSuccessModal: false})}
                       confirmButtonText="OK"
                       declineButtonOnClick={() => this.setState({showSuccessModal: false})}
                       show={this.state.showSuccessModal}
                       title="Success!">
                    Your profile has been saved.
                </Modal>
            </Form>
        );
    }
}

function profileFormValidationConfig(props) {
    const { email, firstName, lastName } = props.profile;

    return {
        fields: ['email', 'firstName', 'lastName'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ],
            firstName: [
                [isRequired, firstName]
            ],
            lastName: [
                [isRequired, lastName]
            ]
        }
    }
}

const mapStateToProps = (state) => {
    return {
        upload: state.upload
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLoggedInUser: () => dispatch(getLoggedInUser()),
        uploadFiles: (files, name) => dispatch(uploadFiles(files, name))
    }
};

export default ProfileForm = validated(profileFormValidationConfig)(connect(mapStateToProps, mapDispatchToProps)(ProfileForm));
