import Request from 'utils/request';
import PATHS from 'utils/paths';
import * as types from './constants';

export const clearWriting = () => ({
    type: types.CLEAR_WRITING
});

const deleteWritingStart = () => ({
    type: types.DELETE_WRITING_START
});

const deleteWritingSuccess = () => ({
    type: types.DELETE_WRITING_SUCCESS
});

const deleteWritingFailure = (error) => ({
    type: types.DELETE_WRITING_FAILURE,
    error
});

/*const getWritingsStart = () => ({
    type: types.GET_WRITINGS_START
});

const getWritingsSuccess = (data) => ({
    type: types.GET_WRITINGS_SUCCESS,
    data
});

const getWritingsFailure = (error) => ({
    type: types.GET_WRITINGS_FAILURE,
    error
});*/

const saveWritingStart = () => ({
    type: types.SAVE_WRITING_START
});

const saveWritingSuccess = () => ({
    type: types.SAVE_WRITING_SUCCESS
});

const saveWritingFailure = (error) => ({
    type: types.SAVE_WRITING_FAILURE,
    error
});

export const setWriting = (data) => ({
    type: types.SET_WRITING,
    data,
});

export const setWritings = (data) => ({
    type: types.SET_WRITINGS,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const deleteWriting = (writingId) => (dispatch, getStore) => {
    dispatch(deleteWritingStart());

    let accountId = getStore().auth.account.id;

    if(writingId == null) {
        writingId = getStore().writings.writing.id;
    }

    const writingRequest = new Request(getStore().auth.token);

    return writingRequest.delete(PATHS.writing.delete(accountId, writingId))
        .then((response) => {
            dispatch(deleteWritingSuccess(writingId));
            let writings = getStore().writings.writings.filter(l => l.id !== writingId);
            dispatch(setWritings(writings));
            return response;
        })
        .catch((err) => {
            // console.log('saveWriting error', err, err.response);
            err.friendlyMessage = 'Error deleting your writing. Please try again.';
            dispatch(deleteWritingFailure(err));
            throw err;
        });
};

export const saveWriting = () => (dispatch, getStore) => {
    dispatch(saveWritingStart());

    let accountId = getStore().auth.account.id;
    let body = getStore().writings.writing;

    const writingRequest = new Request(getStore().auth.token);
    let requestFunc = writingRequest.post;
    let path = PATHS.writing.create(accountId);
    if(body.id) {
        requestFunc = writingRequest.put;
        path = PATHS.writing.save(accountId, body.id);
    }

    return requestFunc(path, body)
        .then((response) => {
            dispatch(saveWritingSuccess());
            let writings = getStore().writings.writings;
            if(body.id) {
                for(var i = 0; i < writings.length; i++) {
                    if(writings[i].id === body.id) {
                        writings[i] = body;
                    }
                }
            } else {
                writings.push(response.data.data);
            }
            dispatch(setWritings(writings));
            return response;
        })
        .catch((err) => {
            // console.log('saveWriting error', err, err.response);
            err.friendlyMessage = 'Error saving your writing. Please try again.';
            dispatch(saveWritingFailure(err));
            throw err;
        });
};
