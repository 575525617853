import clone from 'clone';

import Request from 'utils/request';
import PATHS from 'utils/paths';

import * as types from './constants';
import {setAccount} from "../../Auth/store/actions";

// export const clearStory = () => ({
//     type: types.CLEAR_STORY
// });

const deleteUploadStart = () => ({
    type: types.DELETE_UPLOAD_START
});

const deleteUploadSuccess = () => ({
    type: types.DELETE_UPLOAD_SUCCESS
});

const deleteUploadFailure = (error) => ({
    type: types.DELETE_UPLOAD_FAILURE,
    error
});

// const getStoryStart = () => ({
//     type: types.GET_STORY_START
// });
//
// const getStorySuccess = (data) => ({
//     type: types.GET_STORY_SUCCESS,
//     data
// });
//
// const getStoryFailure = (error) => ({
//     type: types.GET_STORY_FAILURE,
//     error
// });

const saveStoryStart = () => ({
    type: types.SAVE_STORY_START
});

const saveStorySuccess = (data) => ({
    type: types.SAVE_STORY_SUCCESS,
    data
});

const saveStoryFailure = (error) => ({
    type: types.SAVE_STORY_FAILURE,
    error
});

export const setStory = (data) => ({
    type: types.SET_STORY,
    data
});

export const setStoryUploadPercentage = (data) => ({
    type: types.SET_STORY_UPLOAD_PERCENTAGE,
    data
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const deleteUpload = (id, pageName) => (dispatch, getStore) => {
    dispatch(deleteUploadStart());

    let accountId = getStore().auth.account.id;

    const storyRequest = new Request(getStore().auth.token);

    return storyRequest.delete(PATHS.story.deleteUpload(accountId, pageName, id))
        .then((response) => {
            dispatch(deleteUploadSuccess());
            let story = clone(getStore().story.story);
            story[pageName] = story[pageName].filter(p => p.id !== id);
            dispatch(setStory(story));
            return response;
        })
        .catch((err) => {
            // console.log('deleteUpload error', err, err.response);
            err.friendlyMessage = 'Error deleting your media. Please try again.';
            dispatch(deleteUploadFailure(err));
            throw err;
        });
};

export const saveStory = (pageName) => (dispatch, getStore) => {
    dispatch(saveStoryStart());

    let accountId = getStore().auth.account.id;
    let body = getStore().story.story[pageName];

    const storyRequest = new Request(getStore().auth.token);

    return storyRequest.put(PATHS.story.save(accountId, pageName), body)
        .then((response) => {
            let account = clone(getStore().auth.account);
            account.story[pageName] = response.data.data;
            dispatch(saveStorySuccess(account.story));
            dispatch(setAccount(account));
            return response;
        })
        .catch((err) => {
            // console.log('saveStory error', err, err.response);
            err.friendlyMessage = 'Error saving your story. Please log in again.';
            dispatch(saveStoryFailure(err));
            throw err;
        });
};
