import './NewAccount.scss';

import React from 'react';
import { connect } from "react-redux";

import { saveNewAccount, setNewAccount } from "./store/actions";

import H3 from 'components/H3';
import NewAccountForm from './NewAccountForm';

class NewAccount extends React.Component {
    constructor() {
        super();

        this.state = {
        }
    }

    componentWillMount() {
        document.body.classList.add('new-account-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('new-account-page');
    }

    render() {
        // console.log('NewAccount.render');
        return (
            <div className="new-account choose-plan">
                <header className="story-navigation__header">
                    <H3>
                        Set up a new account
                    </H3>
                </header>

                <div className="choose-plan-content">

                    <NewAccountForm
                        accounts={this.props.auth.accounts}
                        email={this.props.auth.profile.email}
                        // hasPromoCode={this.state.hasPromoCode}
                        isSaving={this.props.newAccount.isSavingNewAccount}
                        navigateTo={(path) => this.props.history.push(path)}
                        newAccountData={this.props.newAccount.newAccount}
                        history={this.props.history}
                        // planLevel={this.state.plan}
                        // promoCode={this.state.promoCode}
                        saveNewAccount={this.props.saveNewAccount}
                        setNewAccountData={this.props.setNewAccount}
                        // submitPromoCode={this.submitPromoCode}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        newAccount: state.newAccount
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveNewAccount: () => dispatch(saveNewAccount()),
        setNewAccount: (newAccountObj) => dispatch(setNewAccount(newAccountObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAccount);
