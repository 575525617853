import './Login.scss';

import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import { clearCredentials, login, setCredentials } from 'containers/Auth/store/actions';
import { breakpoints } from 'utils/constants';

import A from 'components/A';
import LoginForm from './LoginForm';
import Logo from 'components/Logo';

class LoginPage extends React.Component {
    componentDidMount() {
        document.body.classList.add('auth-page');
        document.body.classList.add('login-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('auth-page');
        document.body.classList.remove('login-page');
    }

    handleSubmit = (isValid) => {
        if(isValid === true) {
            this.props.login()
                .then(() => {
                    let redirectString = localStorage.getItem('login_redirect');
                    let redirectItems = redirectString ? redirectString.split('||') : null;
                    let redirect = `/plan/${this.props.auth.accounts[0].id}`;
                    if(redirectItems && new Date().getTime() - parseInt(redirectItems[1], 10) <= 300000) {
                        redirect = redirectItems[0];
                    }
                    localStorage.removeItem('login_redirect');
                    this.props.history.push(redirect);
                    this.props.clearCredentials();
                })
            // .catch(err => {
            //     console.log('Error in login: ', err);
            //     console.log(Object.keys(err.response))
            //     console.log(err.response.status)
            //     console.log(err.response.data.error);
            // })
        }
    }

    render() {
        return (
            <div className="login-form--wrapper auth-form--wrapper">
                <MediaQuery minWidth={breakpoints.tabletBreakpoint}>
                    {(matches) => <Logo type={matches ? 'wordmark' : null} />}
                </MediaQuery>

                <div className="login-form__content auth-form__content">
                    <LoginForm
                        credentials={this.props.auth.credentials}
                        error={this.props.auth.loginError}
                        handleSubmit={this.handleSubmit}
                        isLoggingIn={this.props.auth.isLoggingIn}
                        setCredentials={this.props.setCredentials}
                    />

                    <div className="login-page__forgot-password-link">
                        <A to="/auth/forgot-password">Forgot Password</A>
                    </div>

                    <div className="login-page__signup-link">
                        New to My Wonderful Life?<br/> <A to="/auth/signup">Create An Account</A>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCredentials: () => dispatch(clearCredentials()),
        login: () => dispatch(login()),
        setCredentials: (credentialsObj) => dispatch(setCredentials(credentialsObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
