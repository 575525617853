import './Letters.scss';

import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import clone from 'clone';

import { handleTextChange } from 'utils/handle-changes';

import { clearLetter, deleteLetter, saveInstructions, saveLetter, setInstructions, setLetter } from 'containers/Letters/store/actions';

import Button from 'components/Button';
import CreateEditLettersModal from './CreateEditLetterModal';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Table from 'components/Table';
import Textbox from 'components/Textbox';

class Letters extends React.Component {
    constructor() {
        super();

        this.state = {
            showConfirmModal: false,
            showEditModal: false,
            showInstructionsSuccessModal: false
        };
    }

    componentDidMount() {
        document.body.classList.add('letters-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('letters-page');
    }

    finishDeleteLetter = () => {
        this.props.deleteLetter(this.props.letters.letter.id)
            .then(() => {
                this.setState({showConfirmModal: false});
            });
    };

    handleDelete = (letter) => {
        this.props.setLetter(clone(letter));
        this.setState({showConfirmModal: true});
    };

    handleEdit = (letter) => {
        this.props.setLetter(clone(letter));
        this.setState({showEditModal: true});
    };

    handleInstructionsChange = (name, value) => {
        this.props.setInstructions(value);
    };

    handleNew = () => {
        this.setState({showEditModal: true});
    };

    render() {
        let { letters } = this.props.letters;

        let columns = [
            {
                Header: 'To',
                id: 'recipientName',
                accessor: 'recipientName'
            }, {
                Header: 'Email',
                id: 'recipientEmail',
                accessor: 'recipientEmail'
            }, {
                Header: 'Date',
                id: 'composeDate',
                accessor: 'composeDate',
                Cell: (props) => moment(props.original.composeDate).format('MM/DD/YYYY'),
                width: 120
            }, {
                Header: 'Body',
                id: 'body',
                accessor: 'body',
                Cell: (props) => (
                    <div className="letters__body">
                        {props.original.body.split(/\r\n|\r|\n/).map((p) => {
                            if(p) {
                                return <p>{p}</p>;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )
            }, {
                Header: '',
                id: 'actions',
                className: 'letters__actions',
                Cell: (props) => (
                    <React.Fragment>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleEdit(props.original)}>
                            <Icon type="edit" />
                        </Button>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleDelete(props.original)}>
                            <Icon type="delete" />
                        </Button>
                    </React.Fragment>
                ),
                width: 80,
                sortable: false
            }
        ];

        return (
            <div className="letters">
                <header className="story-form__header letters__header">
                    <H3>
                        Letters
                    </H3>

                    <Button onClick={this.handleNew}
                            theme="secondary">
                        Create New
                    </Button>
                </header>

                <div className="plan-form__content">
                    <p>Don’t leave this world with things unsaid. Here’s a spot for you to write letters to loved ones and friends, letting them know how you feel, what you hope for their future, where you buried the gold… anything really. It’s your funeral!</p>
                </div>

                <div className="plan-form--inner letters--inner">
                    <FormGroup>
                        {letters && letters.length > 0 ? (
                            <Table
                                columns={columns}
                                data={letters}
                                pageSize={letters.length}
                            />
                        ) : (
                            <React.Fragment>
                                You haven't written a letter. <Button theme="link" onClick={this.handleNew}>Create one now</Button>.
                            </React.Fragment>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <FormRow>
                            <Textbox
                                id="txtLetterInstructions"
                                label="How would you like these letters distributed?"
                                name="letterInstructions"
                                onChange={(event) => handleTextChange(this.handleInstructionsChange, event)}
                                type="textarea"
                                value={this.props.letters.letterInstructions || ''}
                            />
                        </FormRow>

                        <FormRow>
                            <Button disabled={!this.props.letters.letterInstructions}
                                    onClick={() => {
                                        this.props.saveInstructions()
                                            .then(() => {
                                                this.setState({showInstructionsSuccessModal: true});
                                            });
                                    }}
                                    showActivityIndicator={this.props.letters.isSavingInstructions}>
                                Save Instructions
                            </Button>
                        </FormRow>
                    </FormGroup>
                </div>

                <CreateEditLettersModal
                    closeModal={() => {
                        this.setState({showEditModal: false});
                        this.props.clearLetter();
                    }}
                    error={this.props.letters.saveLetterError}
                    isSavingLetter={this.props.letters.isSavingLetter}
                    letter={this.props.letters.letter}
                    saveLetter={this.props.saveLetter}
                    setLetter={this.props.setLetter}
                    show={this.state.showEditModal}
                />

                <Modal confirmButtonOnClick={() => {
                            this.finishDeleteLetter();
                        }}
                       confirmButtonText="Delete"
                       declineButtonOnClick={() => {
                            this.setState({showConfirmModal: false});
                            this.props.clearLetter();
                        }}
                       declineButtonText="Cancel"
                       show={this.state.showConfirmModal}
                       showActivityIndicator={this.props.letters.isDeletingLetter}
                       title="Are you sure you want to delete this letter?">
                    This action cannot be undone.
                </Modal>

                <Modal confirmButtonOnClick={() => this.setState({showInstructionsSuccessModal: false})}
                       confirmButtonText="OK"
                       declineButtonOnClick={() => this.setState({showInstructionsSuccessModal: false})}
                       show={this.state.showInstructionsSuccessModal}
                       title="Success!">
                    Your instructions are saved.
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        letters: state.letters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearLetter: () => dispatch(clearLetter()),
        deleteLetter: (letterId) => dispatch(deleteLetter(letterId)),
        saveInstructions: () => dispatch(saveInstructions()),
        saveLetter: () => dispatch(saveLetter()),
        setInstructions: (instructions) => dispatch(setInstructions(instructions)),
        setLetter: (letterObj) => dispatch(setLetter(letterObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Letters);
