    import axios from 'axios';

export default class Request {
    constructor(token) {
        // console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL);
        this.config = {
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            validateStatus: (status) => isRequestSuccessful(status)
        };

        if(token != null) {
            this.config.headers['Authorization'] = `Bearer ${token}`;
        }

        this.request = axios.create(this.config);
    }

    get = (url, config) => this.request.get(url, config);

    post = (url, data, config) => this.request.post(url, data, config);

    patch = (url, data, config) => this.request.patch(url, data, config);

    delete = (url, config) => this.request.delete(url, config);

    put = (url, data, config) => this.request.put(url, data, config);
}

export const isRequestSuccessful = (status) => status >= 200 && status < 300;
