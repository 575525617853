export const CLEAR_ACCOUNT = 'Auth/CLEAR_ACCOUNT';
export const CLEAR_CHANGE_PASSWORD_DATA = 'Auth/CLEAR_CHANGE_PASSWORD_DATA';
export const CLEAR_CREDENTIALS = 'Auth/CLEAR_CREDENTIALS';
export const CLEAR_FORGOT_PASSWORD_DATA = 'Auth/CLEAR_FORGOT_PASSWORD_DATA';
export const CLEAR_SIGNUP_DATA = 'Auth/CLEAR_SIGNUP_DATA';

export const GET_LOGGED_IN_USER_START = 'Auth/GET_LOGGED_IN_USER_START';
export const GET_LOGGED_IN_USER_SUCCESS = 'Auth/GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_FAILURE = 'Auth/GET_LOGGED_IN_USER_FAILURE';

export const GET_ACCOUNT_START = 'Auth/GET_ACCOUNT_START';
export const GET_ACCOUNT_SUCCESS = 'Auth/GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'Auth/GET_ACCOUNT_FAILURE';

export const LOGIN_START = 'Auth/LOGIN_START';
export const LOGIN_SUCCESS = 'Auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'Auth/LOGIN_FAILURE';

export const SIGNUP_START = 'Auth/SIGNUP_START';
export const SIGNUP_SUCCESS = 'Auth/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'Auth/SIGNUP_FAILURE';

export const UPDATE_PROFILE_START = 'Auth/PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'Auth/PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'Auth/PROFILE_FAILURE';

export const LOGOUT_START = 'Auth/LOGOUT_START';
export const LOGOUT_SUCCESS = 'Auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'Auth/LOGOUT_FAILURE';

export const RESEND_VERIFICATION_EMAIL_START = 'Auth/RESEND_VERIFICATION_EMAIL_START';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'Auth/RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_FAILURE = 'Auth/RESEND_VERIFICATION_EMAIL_FAILURE';

export const RESET_PASSWORD_START = 'Auth/RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'Auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'Auth/RESET_PASSWORD_FAILURE';

export const SAVE_PROFILE_START = 'Auth/SAVE_PROFILE_START';
export const SAVE_PROFILE_SUCCESS = 'Auth/SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_FAILURE = 'Auth/SAVE_PROFILE_FAILURE';

export const SEND_FORGOT_PASSWORD_EMAIL_START = 'Auth/SEND_FORGOT_PASSWORD_EMAIL_START';
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = 'Auth/SEND_FORGOT_PASSWORD_EMAIL_SUCCESS';
export const SEND_FORGOT_PASSWORD_EMAIL_FAILURE = 'Auth/SEND_FORGOT_PASSWORD_EMAIL_FAILURE';

export const SET_ACCOUNT = 'Auth/SET_ACCOUNT';
export const SET_CHANGE_PASSWORD_DATA = 'Auth/SET_CHANGE_PASSWORD_DATA';
export const SET_CREDENTIALS = 'Auth/SET_CREDENTIALS';
export const SET_FORGOT_PASSWORD_DATA = 'Auth/SET_FORGOT_PASSWORD_DATA';
export const SET_PROFILE = 'Auth/SET_PROFILE';
export const SET_SIGNUP_DATA = 'Auth/SET_SIGNUP_DATA';
export const SET_TOKEN = 'Auth/SET_TOKEN';

export const UPDATE_AUTH = 'Auth/UPDATE_AUTH';

export const VERIFY_EMAIL_START = 'Auth/VERIFY_EMAIL_START';
export const VERIFY_EMAIL_SUCCESS = 'Auth/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'Auth/VERIFY_EMAIL_FAILURE';

export const RESET_STORE = 'Auth/RESET_STORE';
