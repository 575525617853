import clone from 'clone';
import moment from 'moment';

import Request from 'utils/request';
import PATHS from 'utils/paths';

import { LOCALSTORAGE } from 'utils/constants';

import * as types from './constants';

export const clearAccount = () => ({
    type: types.CLEAR_ACCOUNT
});

export const clearChangePasswordData = () => ({
    type: types.CLEAR_CHANGE_PASSWORD_DATA
});

export const clearCredentials = () => ({
    type: types.CLEAR_CREDENTIALS
});

export const clearForgotPasswordData = () => ({
    type: types.CLEAR_FORGOT_PASSWORD_DATA
});

export const clearSignupData = () => ({
    type: types.CLEAR_SIGNUP_DATA
});

const getAccountStart = (hideSpinner) => ({
    type: types.GET_ACCOUNT_START,
    hideSpinner
});

const getAccountSuccess = (data) => ({
    type: types.GET_ACCOUNT_SUCCESS,
    data
});

const getAccountFailure = (error) => ({
    type: types.GET_ACCOUNT_FAILURE,
    error
});

const getLoggedInUserStart = () => ({
    type: types.GET_LOGGED_IN_USER_START
});

const getLoggedInUserSuccess = (data, accounts) => ({
    type: types.GET_LOGGED_IN_USER_SUCCESS,
    data,
    accounts
});

const getLoggedInUserFailure = (error) => ({
    type: types.GET_LOGGED_IN_USER_FAILURE,
    error
});

const loginStart = () => ({
    type: types.LOGIN_START
});

const loginSuccess = (data, accounts) => ({
    type: types.LOGIN_SUCCESS,
    data,
    accounts
});

const loginFailure = (error) => ({
    type: types.LOGIN_FAILURE,
    error
});

const saveProfileStart = () => ({
    type: types.SAVE_PROFILE_START
});

const saveProfileSuccess = (data) => ({
    type: types.SAVE_PROFILE_SUCCESS,
    data
});

const saveProfileFailure = (error) => ({
    type: types.SAVE_PROFILE_FAILURE,
    error
});

const signupStart = () => ({
    type: types.SIGNUP_START
});

const signupSuccess = () => ({
    type: types.SIGNUP_SUCCESS
});

const signupFailure = (error) => ({
    type: types.SIGNUP_FAILURE,
    error
});

// const updateProfileStart = () => ({
//     type: types.UPDATE_PROFILE_START
// });
//
// const updateProfileSuccess = (data) => ({
//     type: types.UPDATE_PROFILE_SUCCESS,
//     data
// });
//
// const updateProfileFailure = (error) => ({
//     type: types.UPDATE_PROFILE_FAILURE,
//     error
// });

const resendVerificationEmailStart = () => ({
    type: types.RESEND_VERIFICATION_EMAIL_START
});

const resendVerificationEmailSuccess = () => ({
    type: types.RESEND_VERIFICATION_EMAIL_SUCCESS
});

const resendVerificationEmailFailure = (error) => ({
    type: types.RESEND_VERIFICATION_EMAIL_FAILURE,
    error
});

const resetPasswordStart = () => ({
    type: types.RESET_PASSWORD_START
});

const resetPasswordSuccess = () => ({
    type: types.RESET_PASSWORD_SUCCESS
});

const resetPasswordFailure = (error) => ({
    type: types.RESET_PASSWORD_FAILURE,
    error
});

const sendForgotPasswordEmailStart = () => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_START
});

const sendForgotPasswordEmailSuccess = () => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS
});

const sendForgotPasswordEmailFailure = (error) => ({
    type: types.SEND_FORGOT_PASSWORD_EMAIL_FAILURE,
    error
});

export const setAccount = (data) => ({
    type: types.SET_ACCOUNT,
    data
});

export const setChangePasswordData = (data) => ({
    type: types.SET_CHANGE_PASSWORD_DATA,
    data
});

export const setCredentials = (data) => ({
    type: types.SET_CREDENTIALS,
    data
});

export const setForgotPasswordData = (data) => ({
    type: types.SET_FORGOT_PASSWORD_DATA,
    data
});

export const setToken = (data) => ({
    type: types.SET_TOKEN,
    data
});

export const setProfile = (data) => ({
    type: types.SET_PROFILE,
    data,
});

export const setSignupData = (data) => ({
    type: types.SET_SIGNUP_DATA,
    data,
});

// const logoutStart = () => ({
//     type: types.LOGOUT_START,
// });
//
// const logoutSuccess = () => ({
//     type: types.LOGOUT_SUCCESS,
// });
//
// const logoutFailure = (error) => ({
//     type: types.LOGOUT_FAILURE,
//     error,
// });

const verifyEmailStart = () => ({
    type: types.VERIFY_EMAIL_START,
});

const verifyEmailSuccess = (data, accounts, token) => ({
    type: types.VERIFY_EMAIL_SUCCESS,
    data,
    accounts,
    token
});

const verifyEmailFailure = (error) => ({
    type: types.VERIFY_EMAIL_FAILURE,
    error,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const changePassword = () => (dispatch, getStore) => {
    dispatch(saveProfileStart());

    let oldPassword = getStore().auth.changePasswordData.oldPassword;
    let password = getStore().auth.changePasswordData.password;

    const saveProfileRequest = new Request(getStore().auth.token);

    return saveProfileRequest.put(PATHS.auth.saveProfile(), {oldPassword, password})
        .then((response) => {
            dispatch(saveProfileSuccess(getStore().auth.profile));
            return response;
        })
        .catch((err) => {
            console.log(err, err.response);
            err.friendlyMessage = 'Unable to change your password. Please try again.';
            dispatch(saveProfileFailure(err));
            throw err;
        });
};

export const getAccount = (accountId, hideSpinner) => (dispatch, getStore) => {
    dispatch(getAccountStart(hideSpinner));
    if(accountId == null) {
        accountId = getStore().auth.account.id;
    }
    const userRequest = new Request(getStore().auth.token);

    return userRequest.get(PATHS.auth.getAccountById(accountId))
        .then((response) => {
            dispatch(getAccountSuccess(response.data.data));
            return response;
        })
        .catch((err) => {
            // console.log('getAccountByIdError', err, err.response);
            err.friendlyMessage = 'Error getting your information. Please log in again.';
            dispatch(getAccountFailure(err));
            throw err;
        });
};

export const getLoggedInUser = (token) => (dispatch, getStore) => {
    dispatch(getLoggedInUserStart());
    if(token == null) {
        token = getStore().auth.token;
    }
    const userRequest = new Request(token);

    return userRequest.get(PATHS.auth.getLoggedInUser())
        .then((response) => {
            dispatch(getLoggedInUserSuccess(response.data.data.user, response.data.data.accounts));
            return response;
        })
        .catch((err) => {
            // console.log('getLoggedInUserError', err, err.response);
            err.friendlyMessage = 'Error getting your information. Please log in again.';
            dispatch(getLoggedInUserFailure(err));
            throw err;
        });
};

export const login = () => (dispatch, getStore) => {
    dispatch(loginStart());

    let credentials = getStore().auth.credentials;

    const loginRequest = new Request();

    return loginRequest.post(PATHS.auth.login(), credentials)
        .then((response) => {
            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, response.data.data.token);
            dispatch(setToken(response.data.data.token));
            dispatch(loginSuccess(response.data.data.user, response.data.data.accounts));
            return response;
        })
        .catch((err) => {
            // console.log(err, err.response);
            if (err.response && err.response.data && err.response.data.error === 'login.user.unverified') {
                err.friendlyMessage = 'Your email is not verified. Please reset your password to complete verification.'
            } else {
                err.friendlyMessage = 'Incorrect username or password. Please try again.';
            }

            dispatch(loginFailure(err));
            throw err;
        });
};

export const resendVerificationEmail = (email) => (dispatch, getStore) => {
    dispatch(resendVerificationEmailStart());

    if(!email) {
        email = getStore().auth.signupData.user.email;
    }

    const resendVerificationEmailRequest = new Request();

    return resendVerificationEmailRequest.post(PATHS.auth.resendVerificationEmail(email))
        .then((response) => {
            dispatch(resendVerificationEmailSuccess());
            return response;
        })
        .catch((err) => {
            // console.log('resend verification email failure', err, err.response);
            err.friendlyMessage = 'Error resending your verification email. Please try again.';
            dispatch(resendVerificationEmailFailure(err));
            throw err;
        });
};

export const resetPassword = (token) => (dispatch, getStore) => {
    dispatch(resetPasswordStart());

    let password = getStore().auth.credentials.password;

    const resetPasswordRequest = new Request();

    return resetPasswordRequest.post(PATHS.auth.resetPassword(), {password, token})
        .then((response) => {
            dispatch(resetPasswordSuccess());
            return response;
        })
        .catch((err) => {
            // console.log(err, err.response);
            err.friendlyMessage = 'Unable to reset password. Please try again.';
            dispatch(resetPasswordFailure(err));
            throw err;
        });
};

export const saveProfile = () => (dispatch, getStore) => {
    dispatch(saveProfileStart());

    let profile = getStore().auth.profile;

    const saveProfileRequest = new Request(getStore().auth.token);

    return saveProfileRequest.put(PATHS.auth.saveProfile(), profile)
        .then((response) => {
            dispatch(saveProfileSuccess(response.data.data));
            return response;
        })
        .catch((err) => {
            // console.log(err, err.response);
            err.friendlyMessage = 'Unable to save your profile. Please try again.';
            dispatch(saveProfileFailure(err));
            throw err;
        });
};

export const sendForgotPasswordEmail = () => (dispatch, getStore) => {
    dispatch(sendForgotPasswordEmailStart());

    let email = getStore().auth.credentials.email;

    const forgotPasswordRequest = new Request();

    return forgotPasswordRequest.post(PATHS.auth.forgotPassword(), {email})
        .then((response) => {
            dispatch(sendForgotPasswordEmailSuccess());
            return response;
        })
        .catch((err) => {
            // console.log(err, err.response);
            err.friendlyMessage = 'Unable to reset password. Please try again.';
            dispatch(sendForgotPasswordEmailFailure(err));
            throw err;
        });
};

export const signup = (invite) => (dispatch, getStore) => {
    dispatch(signupStart());

    let signupData = clone(getStore().auth.signupData);
    let signupDataFor = signupData.for;
    delete signupData.for;
    signupData.user.acceptedTermsAt = moment().format();

    let path = PATHS.auth.signupSomeoneElse();

    if(signupDataFor === 'me') {
        signupData = signupData.user;
        path = PATHS.auth.signup();
    }

    if(invite) {
        signupData = signupData.user;
        signupData.token = invite;
        path = PATHS.auth.acceptInvite();
    }

    const signupRequest = new Request();
    return signupRequest.post(path, signupData)
        .then((res) => {
            dispatch(signupSuccess());
            return res;
        })
        .catch((err) => {
            // console.log(err, err.response);
            if(err.response.status === 400) {
                err.friendlyMessage = err.response.data.error;
            } else {
                err.friendlyMessage = 'There was an error signing up. Please try again.';
            }
            dispatch(signupFailure(err));
            throw err;
        });

};

export const verifyEmail = (token) => (dispatch) => {
    dispatch(verifyEmailStart());

    const verifyRequest = new Request();

    return verifyRequest.post(PATHS.auth.verifyEmail(token))
        .then((response) => {
            dispatch(verifyEmailSuccess(response.data.data.user, response.data.data.accounts, response.data.data.token));
            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, response.data.data.token);
            return response;
        })
        .catch((err) => {
            // console.log('verifyEmailErr', err, err.response);
            err.friendlyMessage = 'Error verifying your email. Please try again.';
            dispatch(verifyEmailFailure(err));
            throw err;
        });
};
