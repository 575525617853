import './AccountMenu.scss';

import React from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import { withRouter } from "react-router";

import { clearAccount, setAccount } from 'containers/Auth/store/actions';

import Button from 'components/Button';
import Icon from 'components/Icon';

class AccountMenu extends React.Component {
    constructor() {
        super();

        this.height = window.innerHeight - (65 + 82);

        this.state = {
            height: 0
        }
    }

    handleAccountClick = (account) => {
        /*if(this.props.auth.account.id === account.id) {
            return;
        }*/

        this.props.setAccount(account);
        console.log('push plan')
        this.props.history.push(`/plan/${account.id}`);
        this.openCloseMenu();
        document.body.classList.toggle('open-menu');
    };

    openCloseMenu = () => {
        this.setState({height: this.state.height !== 0 ? 0 : this.height});
    };

    render() {
        let { account, accounts } = this.props.auth;

        return (
            <div className="c-account-menu">
                <Button className="c-account-menu__current" noStyles onClick={this.openCloseMenu}>
                    <div className="c-account-menu__current-text">
                        Current plan view:
                    </div>
                    <div className="c-account-menu__current-name">
                        {window.location.pathname === '/new' ? "New Plan" : <>{account.firstName} {account.lastName} {account.userId === this.props.auth.profile.id ? '(you)' : null}</> }
                    </div>

                    <Icon type="angle-down" />
                </Button>

                <div className="c-account-menu__accounts" style={{height: this.state.height}}>
                    <div className="c-account-menu__accounts--inner"  style={{height: this.height}}>
                        <div className="c-account-menu__accounts--wrapper">
                            {accounts.map((a, idx) => {
                                return (
                                <Button key={`btn_${idx}`} className={classNames('c-account-menu__account', {'a-account-menu__account-active': a.id === account.id && window.location.pathname !== '/new'})} noStyles onClick={() => this.handleAccountClick(a)}>
                                    {a.id === account.id && window.location.pathname !== '/new' ? <Icon type="check" /> : null}
                                    {a.firstName} {a.lastName} {a.userId === this.props.auth.profile.id ? '(you)' : null}
                                </Button>
                            )})}

                            <Button  className={classNames('c-account-menu__account a-account-menu__account-new', {'a-account-menu__account-active': window.location.pathname === '/new'})} noStyles to="/new">
                                {window.location.pathname === '/new' ? <Icon type="check" /> : null}
                                Start New Plan
                            </Button>
                        </div>
                        <Button className="c-account-menu__cancel-button" noStyles onClick={this.openCloseMenu}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearAccount: () => dispatch(clearAccount()),
        setAccount: (accountObj) => dispatch(setAccount(accountObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountMenu));
