import './PlanFormPage.scss';

import React from 'react';
import { connect } from "react-redux";
import update from "immutability-helper";
import clone from 'clone';

import { handleSelectChange, handleTextChange } from 'utils/handle-changes';

import { savePlan, setPlan } from 'containers/Plan/store/actions';
import { uploadFiles } from 'containers/Upload/actions';

import { PLAN_CONTENT } from 'containers/Plan/plan-content';
import { DAYS, MONTHS } from 'utils/constants';

import A from 'components/A';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import FormColumn from 'components/FormColumn';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import H3 from 'components/H3';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Modal from 'components/Modal';
import RadioGroup from 'components/RadioGroup';
import Radio from 'components/Radio';
import SimpleFileUpload from 'components/FileUpload/SimpleFileUpload';
import Textbox from 'components/Textbox';

class PlanFormPage extends React.Component {
    constructor() {
        super();

        this.state = {
            modalContent: '',
            showModal: false,
            showSuccessModal: false,
            startingData: null,
            previewImages: {}
        };

        this.years = [];
    }

    componentDidMount() {
        document.body.classList.add('plan-form-page');

        this.setState({startingData: clone(this.props.plan.plan)});

        if(this.props.match.params.pageName === 'obituary') {
            for(var i = 1910; i < new Date().getFullYear(); i++) {
                this.years.push({label: i.toString(), value: i.toString()});
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('plan-form-page');
    }

    handleBack = () => {
        this.props.setPlan(this.state.startingData);
        // console.log('push plan')
        this.props.history.push(`/plan/${this.props.account.id}`);
    };

    handleChange = (name, value, object) => {
        let newPlanObj = update(this.props.plan.plan, {});
        if(object){
            if(newPlanObj[object] == null) {
                newPlanObj[object] = {};
            }
            newPlanObj[object][name] = value;
        } else {
            newPlanObj[name] = value;
        }
        this.props.setPlan(newPlanObj);
    };

    handleFileDrop = (goodFiles, badFiles, name) => {
        this.props.uploadFiles(goodFiles, name)
            .then((res) => {
                const pageName = this.props.match.params.pageName;
                this.handleChange(`${name}StorageKey`, res.files[0].storageKey, pageName);
                this.handleChange(`${name}Url`, res.files[0].uploadUrl.split('?')[0], pageName);
                if(goodFiles[0].type.indexOf('image/') === 0) {
                    let previewImages = clone(this.state.previewImages);
                    previewImages[`${name}Preview`] = URL.createObjectURL(goodFiles[0]);
                    previewImages[`${name}Name`] = goodFiles[0].name;
                    this.setState({previewImages});
                }
            })
    };

    handleSave = () => {
        this.props.savePlan(this.props.match.params.pageName)
            .then((res) => {
                this.setState({previewImages: {}, showSuccessModal: true});
            })
            .catch((err) => {

            });
    };

    onModalClick = (field) => {
        if(field.modal == null) {
            return null;
        }

        this.setState({
            modalContent: field.modal(),
            showModal: true
        });
    };

    renderField = (field) => {
        const pageName = this.props.match.params.pageName;
        let data = this.props.plan.plan[pageName] || {};
        let {account, upload } = this.props;

        if(field.showConditional && field.showConditional(data) === false) {
            return null;
        }

        switch(field.type) {
            case 'birthdate':
                return (
                    <FormRow columnCount={3} key={field.name}>
                        <Label>{field.label}</Label>
                        <FormColumn>
                            <Dropdown
                                disabled={account.myRole === 'member'}
                                id="selBirthMonth"
                                label="Month"
                                name="dateOfBirthMonth"
                                onChange={handleSelectChange.bind(this, this.handleChange, "dateOfBirthMonth", pageName, null)}
                                options={MONTHS}
                                placeholder={'select month'}
                                value={data.dateOfBirthMonth}
                            />
                        </FormColumn>
                        <FormColumn>
                            <Dropdown
                                disabled={account.myRole === 'member'}
                                id="selBirthDate"
                                label="Day"
                                name="dateOfBirthDay"
                                onChange={handleSelectChange.bind(this, this.handleChange, "dateOfBirthDay", pageName, null)}
                                options={DAYS}
                                placeholder={'select date'}
                                value={data.dateOfBirthDay}
                            />
                        </FormColumn>
                        <FormColumn>
                            <Dropdown
                                disabled={account.myRole === 'member'}
                                id="selBirthYear"
                                label="Year"
                                name="dateOfBirthYear"
                                onChange={handleSelectChange.bind(this, this.handleChange, "dateOfBirthYear", pageName, null)}
                                options={this.years}
                                placeholder={'select year'}
                                value={data.dateOfBirthYear}
                            />
                        </FormColumn>
                    </FormRow>
                );
            case 'checkboxes':
                return (
                    <FormRow key={field.name}>
                        <Label hasModal={field.modal != null}
                               moreInfoContent={field.moreInfoContent}
                               onModalClick={() => this.onModalClick(field)}
                               showModalButton={field.modal != null && field.tooltip == null}
                               tooltip={field.tooltip}>
                            {field.label}
                        </Label>

                        <RadioGroup horizontal>
                            {field.options.map((option) => (
                                <Checkbox
                                    checked={data[option.name] === 'yes'}
                                    disabled={account.myRole === 'member'}
                                    id={`chk${option.name}`}
                                    label={option.label}
                                    name={option.name}
                                    onChange={() => this.handleChange(option.name, data[option.name] === 'yes' ? 'no' : 'yes', pageName)}
                                />
                            ))}
                        </RadioGroup>
                    </FormRow>
                );
            case 'content':
                return (
                    <FormRow key={field.name}>
                        <Label>{field.label}</Label>
                    </FormRow>
                );
            case 'dropdown':
                return (
                    <FormRow key={field.name}>
                        <Dropdown
                            disabled={account.myRole === 'member'}
                            hasModal={field.modal != null}
                            id={`sel${field.name}`}
                            label={field.label}
                            moreInfoContent={field.moreInfoContent}
                            name={field.name}
                            onChange={handleSelectChange.bind(this, this.handleChange, field.name, pageName, null)}
                            onModalClick={() => this.onModalClick(field)}
                            options={field.options}
                            placeholder={field.placeholder || 'select'}
                            showModalButton={field.modal != null && field.tooltip == null}
                            tooltip={field.tooltip}
                            value={data[field.name]}
                        />
                    </FormRow>
                );
            case 'radio':
                return (
                    <FormRow key={field.name}>
                        <Label hasModal={field.modal != null}
                               onModalClick={() => this.onModalClick(field)}
                               showModalButton={field.modal != null && field.tooltip == null}
                               tooltip={field.tooltip}>
                            {field.label}
                        </Label>
                        <RadioGroup horizontal>
                            {field.options.map((option) => (
                                <Radio
                                    checked={data[field.name] === option.value}
                                    disabled={account.myRole === 'member'}
                                    id={`rdo${field.name}${option.value}`}
                                    key={option.value}
                                    label={option.label}
                                    name={field.name}
                                    onChange={() => this.handleChange(field.name, option.value, pageName)}
                                    value={option.value}
                                />
                            ))}
                        </RadioGroup>
                    </FormRow>
                );
            case 'text':
            case 'textarea':
                return (
                    <FormRow key={field.name}>
                        <Textbox
                            disabled={account.myRole === 'member'}
                            hasModal={field.modal != null}
                            id={`txt${field.name}`}
                            instructions={field.instructions}
                            label={field.label}
                            moreInfoContent={field.moreInfoContent}
                            name={field.name}
                            onChange={(event) => handleTextChange(this.handleChange, event, pageName)}
                            onModalClick={() => this.onModalClick(field)}
                            showModalButton={field.modal != null && field.tooltip == null}
                            tooltip={field.tooltip}
                            type={field.type}
                            value={data[field.name] || ''}
                        />
                    </FormRow>
                );
            case 'upload':
                //let filename = getFileName()
                return (
                    <FormRow key={field.name}>
                        <Label>{field.label}</Label>
                        <div className="plan-form__upload-wrapper">
                            <SimpleFileUpload
                                disabled={account.myRole === 'member'}
                                isUploading={upload[`${field.name}IsUploading`]}
                                name={field.name}
                                onDrop={(goodFiles, badFiles) => this.handleFileDrop(goodFiles, badFiles, field.name)}
                                preview={this.state.previewImages[`${field.name}Preview`] || data[`${field.name}Url`]}
                                types="image/*"
                                uploadPercentage={upload[`${field.name}UploadPercentage`]}
                            />
                        </div>
                    </FormRow>
                );
            default:
                return null;
        }
    };

    render() {
        const pageName = this.props.match.params.pageName;
        const pageContent = PLAN_CONTENT[pageName];
        const data = this.props.plan.plan[pageName] || {};
        const account = this.props.account;

        return (
            <div className="plan-form--wrapper">
                <div className="plan-form__links">
                    <Button className="plan-form__back-link"
                            noStyles
                            onClick={this.handleBack}>
                        <Icon type="chevron-left" /> Back
                    </Button>

                    <A className="plan-form__ideas-link" href="#">
                        Find Ideas <Icon type="external-link" />
                    </A>
                </div>
                <H3>
                    <img src={`/img/page-icons/${pageName}.svg`} alt={pageContent.title} /> {pageContent.title}
                </H3>

                {pageContent.content ? (
                    <div className="plan-form__content">
                        {pageContent.content()}
                    </div>
                ) : null}

                <Form className="plan-form">
                    <div className="plan-form--inner">
                        {pageContent.fieldGroups.map((group, i) => {
                            if(group.showConditional && group.showConditional(data) === false) {
                                return null;
                            }

                            return (
                                <FormGroup key={i}>
                                    {group.title ? (
                                        <H4>
                                            {group.title}
                                        </H4>
                                    ) : null}

                                    {group.content ? group.content() : null}

                                    {group.fields.map((field) => this.renderField(field))}
                                </FormGroup>
                            );
                        })}
                    </div>

                    <FormRow>
                        <Button disabled={account.myRole === 'member'}
                                onClick={this.handleSave}
                                showActivityIndicator={this.props.plan.isSavingPlan}>
                            Save
                        </Button>
                        <Button disabled={account.myRole === 'member'}
                                className="plan-form__cancel"
                                noStyles
                                onClick={this.handleBack}>
                            Cancel
                        </Button>
                    </FormRow>
                </Form>

                <Modal confirmButtonOnClick={() => this.setState({
                           modalContent: '',
                           showModal: false
                       })}
                       confirmButtonText="OK"
                       declineButtonOnClick={() => this.setState({
                           modalContent: '',
                           showModal: false
                       })}
                       show={this.state.showModal}>
                    {this.state.modalContent}
                </Modal>

                <Modal confirmButtonOnClick={() => {
                           this.setState({showSuccessModal: false});
                    // console.log('push plan')
                           this.props.history.push(`/plan/${account.id}`);
                       }}
                       confirmButtonText="OK"
                       declineButtonOnClick={() => {
                           this.setState({showSuccessModal: false});
                           // console.log('push plan')
                           this.props.history.push(`/plan/${account.id}`);
                       }}
                       show={this.state.showSuccessModal}
                       title="Success!">
                    Your plan has been saved.
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.auth.account,
        plan: state.plan,
        upload: state.upload
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        savePlan: (pageName) => dispatch(savePlan(pageName)),
        setPlan: (planObj) => dispatch(setPlan(planObj)),
        uploadFiles: (filesArray, name) => dispatch(uploadFiles(filesArray, name))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanFormPage);
