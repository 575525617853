import './PrintPlan.scss';

import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';

import { PLAN_CONTENT } from 'containers/Plan/plan-content';

import H1 from 'components/H1';
import H4 from 'components/H4';
import Logo from 'components/Logo';


class PrintPlan extends React.Component {
    componentWillMount() {
        document.body.classList.add('print-plan-page');
    }

    componentWillUnmount() {
        document.body.classList.remove('print-plan-page');
    }

    getDisplayData = (field, data) => {
        let value = data[field.name];

        switch(field.type) {
            case 'birthdate':
                return moment(`${data.dateOfBirthMonth}/${data.dateOfBirthDay}/${data.dateOfBirthYear}`).format('MMM DD, YYYY');
            case 'checkboxes':
            case 'dropdown':
            case 'radio':
                let matchingOption = field.options.filter(o => o.value === value);
                if(matchingOption.length === 0) {
                    return '';
                }
                return matchingOption[0].label;
            case 'text':
            case 'textarea':
                return value;
            case 'upload':
                return (
                    <div className="print-plan__image">
                        <a href={data[`${field.name}Url`]} target="_blank" rel="noopener noreferrer">
                            <img src={data[`${field.name}Url`]} alt="" />
                        </a>
                    </div>
                );
            default:
                return value;
        }
    };

    render() {
        let { account } = this.props.auth;
        return (
            <div className="print-plan">
                <header className="print-plan__header">
                    <Logo type="wordmark" />

                    <H1>
                        {account.firstName} {account.lastName}'s Plan
                    </H1>
                </header>

                {Object.keys(PLAN_CONTENT).map((key) => {
                    let content = PLAN_CONTENT[key];
                    let data = this.props.plan.plan[key] || {};
                    // console.log(data, content)
                    return (
                        <div className="print-plan__section">
                            <H4>
                                <img src={`/img/page-icons/${key}.svg`} alt={content.title}/> {content.title}
                            </H4>

                            <div className="print-plan__section-content">
                                {content.fieldGroups.map((group) => {
                                    if(group.showConditional && group.showConditional(data) === false) {
                                        return null;
                                    }

                                    return (
                                        <React.Fragment>
                                            {group.fields.map((field) => {
                                                if((field.showConditional && field.showConditional(data) === false)) {
                                                    return null;
                                                }

                                                if((field.type !== 'birthdate' && !data[field.name] && field.type !== 'upload') || (field.type === 'birthdate' && (!data.dateOfBirthDay || !data.dateOfBirthMonth || !data.dateOfBirthYear))) {
                                                    return null;
                                                }

                                                if(field.type === 'upload' && !data[`${field.name}Url`]) {
                                                    return null;
                                                }

                                                // console.log(field);

                                                return (
                                                    <div className="print-plan__field">
                                                        <strong>{field.label} </strong>{this.getDisplayData(field, data)}
                                                    </div>
                                                )
                                            })}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        plan: state.plan
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintPlan);
