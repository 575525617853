import './Plan.scss';

import React from 'react';
import { connect } from "react-redux";
import { Route, Switch } from 'react-router-dom';

import { setAccount } from 'containers/Auth/store/actions';

import PlanFormPage from './PlanFormPage';
import PlanNavigationPage from './PlanNavigationPage';
import PrintPlan from './PrintPlan';

class Plan extends React.Component {
    componentDidMount() {
        document.body.classList.add('plan-page');

        let accountId = parseInt(this.props.match.params.accountId);
        let { account, accounts } = this.props.auth;

        if(accountId !== account.id) {
            for (var i = 0; i < accounts.length; i++) {
                if (accounts[i].id === accountId) {
                    this.props.setAccount(accounts[i]);
                    break;
                }
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('plan-page');
    }

    render() {
        return (
            <div className="plan-content">
                <Switch>
                    <Route path="/plan/:accountId" exact component={PlanNavigationPage} />
                    <Route path="/plan/:accountId/print" exact component={PrintPlan} />
                    <Route path="/plan/:accountId/:pageName" component={PlanFormPage} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (accountObj) => dispatch(setAccount(accountObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
