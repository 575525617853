import Request from 'utils/request';
import PATHS from 'utils/paths';

import * as types from './constants';

export const clearKid = () => ({
    type: types.CLEAR_KID
});

export const clearPet = () => ({
    type: types.CLEAR_PET
});

const deleteKidStart = () => ({
    type: types.DELETE_KID_START
});

const deleteKidSuccess = () => ({
    type: types.DELETE_KID_SUCCESS
});

const deleteKidFailure = (error) => ({
    type: types.DELETE_KID_FAILURE,
    error
});

const deletePetStart = () => ({
    type: types.DELETE_PET_START
});

const deletePetSuccess = () => ({
    type: types.DELETE_PET_SUCCESS
});

const deletePetFailure = (error) => ({
    type: types.DELETE_PET_FAILURE,
    error
});

const saveKidStart = () => ({
    type: types.SAVE_KID_START
});

const saveKidSuccess = () => ({
    type: types.SAVE_KID_SUCCESS
});

const saveKidFailure = (error) => ({
    type: types.SAVE_KID_FAILURE,
    error
});

const savePetStart = () => ({
    type: types.SAVE_PET_START
});

const savePetSuccess = () => ({
    type: types.SAVE_PET_SUCCESS
});

const savePetFailure = (error) => ({
    type: types.SAVE_PET_FAILURE,
    error
});

export const setKid = (data) => ({
    type: types.SET_KID,
    data,
});

export const setKids = (data) => ({
    type: types.SET_KIDS,
    data,
});

export const setPet = (data) => ({
    type: types.SET_PET,
    data,
});

export const setPets = (data) => ({
    type: types.SET_PETS,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const deleteKid = (kidId) => (dispatch, getStore) => {
    dispatch(deleteKidStart());

    let accountId = getStore().auth.account.id;

    if(kidId == null) {
        kidId = getStore().kids.kid.id;
    }

    const kidRequest = new Request(getStore().auth.token);

    return kidRequest.delete(PATHS.family.deleteKid(accountId, kidId))
        .then((response) => {
            dispatch(deleteKidSuccess(kidId));
            let kids = getStore().family.kids.filter(l => l.id !== kidId);
            dispatch(setKids(kids));
            return response;
        })
        .catch((err) => {
            // console.log('deleteKid error', err, err.response);
            err.friendlyMessage = 'Error deleting your kid. Please try again.';
            dispatch(deleteKidFailure(err));
            throw err;
        });
};

export const deletePet = (petId) => (dispatch, getStore) => {
    dispatch(deletePetStart());

    let accountId = getStore().auth.account.id;

    if(petId == null) {
        petId = getStore().pets.pet.id;
    }

    const petRequest = new Request(getStore().auth.token);

    return petRequest.delete(PATHS.family.deletePet(accountId, petId))
        .then((response) => {
            dispatch(deletePetSuccess(petId));
            let pets = getStore().family.pets.filter(l => l.id !== petId);
            dispatch(setPets(pets));
            return response;
        })
        .catch((err) => {
            // console.log('deletePet error', err, err.response);
            err.friendlyMessage = 'Error deleting your pet. Please try again.';
            dispatch(deletePetFailure(err));
            throw err;
        });
};

export const saveKid = () => (dispatch, getStore) => {
    dispatch(saveKidStart());

    let accountId = getStore().auth.account.id;
    let body = getStore().family.kid;

    const kidRequest = new Request(getStore().auth.token);
    let requestFunc = kidRequest.post;
    let path = PATHS.family.createKid(accountId);
    if(body.id) {
        requestFunc = kidRequest.put;
        path = PATHS.family.saveKid(accountId, body.id);
    }

    return requestFunc(path, body)
        .then((response) => {
            dispatch(saveKidSuccess());
            let kids = getStore().family.kids;
            if(body.id) {
                for(var i = 0; i < kids.length; i++) {
                    if(kids[i].id === body.id) {
                        kids[i] = body;
                    }
                }
            } else {
                kids.push(response.data.data);
            }
            dispatch(setKids(kids));
            return response;
        })
        .catch((err) => {
            // console.log('saveKid error', err, err.response);
            err.friendlyMessage = 'Error saving your kid. Please try again.';
            dispatch(saveKidFailure(err));
            throw err;
        });
};

export const savePet = () => (dispatch, getStore) => {
    dispatch(savePetStart());

    let accountId = getStore().auth.account.id;
    let body = getStore().family.pet;

    const petRequest = new Request(getStore().auth.token);
    let requestFunc = petRequest.post;
    let path = PATHS.family.createPet(accountId);
    if(body.id) {
        requestFunc = petRequest.put;
        path = PATHS.family.savePet(accountId, body.id);
    }

    return requestFunc(path, body)
        .then((response) => {
            dispatch(savePetSuccess());
            let pets = getStore().family.pets;
            if(body.id) {
                for(var i = 0; i < pets.length; i++) {
                    if(pets[i].id === body.id) {
                        pets[i] = body;
                    }
                }
            } else {
                pets.push(response.data.data);
            }
            dispatch(setPets(pets));
            return response;
        })
        .catch((err) => {
            // console.log('savePet error', err, err.response);
            err.friendlyMessage = 'Error saving your pet. Please try again.';
            dispatch(savePetFailure(err));
            throw err;
        });
};
