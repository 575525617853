import Request from 'utils/request';
import PATHS from 'utils/paths';

import * as types from './constants';
import {setAccount} from "../../Auth/store/actions";

// export const clearNewAccount = () => ({
//     type: types.CLEAR_NEW_ACCOUNT
// });

const saveNewAccountStart = () => ({
    type: types.SAVE_NEW_ACCOUNT_START
});

const saveNewAccountSuccess = (data) => ({
    type: types.SAVE_NEW_ACCOUNT_SUCCESS,
    data
});

const saveNewAccountFailure = (error) => ({
    type: types.SAVE_NEW_ACCOUNT_FAILURE,
    error
});

export const setNewAccount = (data) => ({
    type: types.SET_NEW_ACCOUNT,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const saveNewAccount = () => async (dispatch, getStore) => {
    dispatch(saveNewAccountStart());

    let newAccount = getStore().newAccount.newAccount;

    const newAccountRequest = new Request(getStore().auth.token);

    // console.log('saveNewAccount', newAccount);

    try {
        let newAccountResponse = await newAccountRequest.post(PATHS.newAccount.save(newAccount.for === 'me'), newAccount);
        dispatch(saveNewAccountSuccess(newAccountResponse.data.data));
        dispatch(setAccount(newAccountResponse.data.data))
        return newAccountResponse;
    } catch(err) {
        // console.log('saveNewAccount error', err);
        err.friendlyMessage = 'Error creating your new plan. Please log in again.';
        dispatch(saveNewAccountFailure(err));
        throw err;
    }
};
