import './Story.scss';

import React from 'react';
import { connect } from "react-redux";
import { Route, Switch } from 'react-router-dom';

import { setAccount } from 'containers/Auth/store/actions';

import Family from 'containers/Family';
import Letters from 'containers/Letters';
import Memories from 'containers/Memories';
import Music from 'containers/Music';
import StoryFormPage from './StoryFormPage';
import StoryNavigationPage from './StoryNavigationPage';
import Stuff from 'containers/Stuff';
import Writings from 'containers/Writings';

class Story extends React.Component {
    componentDidMount() {
        document.body.classList.add('story-page');

        let accountId = parseInt(this.props.match.params.accountId);
        let { account, accounts } = this.props.auth;

        if(accountId !== account.id) {
            for (var i = 0; i < accounts.length; i++) {
                if (accounts[i].id === accountId) {
                    this.props.setAccount(accounts[i]);
                    break;
                }
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('story-page');
    }

    render() {
        return (
            <div className="story-content">
                <Switch>
                    <Route path="/story/:accountId/family" exact component={Family} />
                    <Route path="/story/:accountId/letters" exact component={Letters} />
                    <Route path="/story/:accountId/memories" exact component={Memories} />
                    <Route path="/story/:accountId/songs" exact component={Music} />
                    <Route path="/story/:accountId/stuff" exact component={Stuff} />
                    <Route path="/story/:accountId/stuff/possessions" exact component={Stuff} />
                    <Route path="/story/:accountId/writings" exact component={Writings} />
                    <Route path="/story/:accountId" exact component={StoryNavigationPage} />
                    <Route path="/story/:accountId/:pageName" component={StoryFormPage} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (accountObj) => dispatch(setAccount(accountObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Story);
