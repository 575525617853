import Request from 'utils/request';
import PATHS from 'utils/paths';
import * as types from './constants';

export const clearMusic = () => ({
    type: types.CLEAR_MUSIC
});

const deleteMusicStart = () => ({
    type: types.DELETE_MUSIC_START
});

const deleteMusicSuccess = () => ({
    type: types.DELETE_MUSIC_SUCCESS
});

const deleteMusicFailure = (error) => ({
    type: types.DELETE_MUSIC_FAILURE,
    error
});

const saveMusicStart = () => ({
    type: types.SAVE_MUSIC_START
});

const saveMusicSuccess = () => ({
    type: types.SAVE_MUSIC_SUCCESS
});

const saveMusicFailure = (error) => ({
    type: types.SAVE_MUSIC_FAILURE,
    error
});

export const setMusic = (data) => ({
    type: types.SET_MUSIC,
    data,
});

export const setMusicList = (data) => ({
    type: types.SET_MUSIC_LIST,
    data,
});

export const resetStore = () => ({
    type: types.RESET_STORE,
});

export const deleteMusic = (musicId) => (dispatch, getStore) => {
    dispatch(deleteMusicStart());

    let accountId = getStore().auth.account.id;

    if(musicId == null) {
        musicId = getStore().music.music.id;
    }

    const musicRequest = new Request(getStore().auth.token);

    return musicRequest.delete(PATHS.music.delete(accountId, musicId))
        .then((response) => {
            dispatch(deleteMusicSuccess(musicId));
            let music = getStore().music.musicList.filter(l => l.id !== musicId);
            dispatch(setMusicList(music));
            return response;
        })
        .catch((err) => {
            // console.log('saveMusic error', err, err.response);
            err.friendlyMessage = 'Error deleting your music. Please try again.';
            dispatch(deleteMusicFailure(err));
            throw err;
        });
};

export const saveMusic = () => (dispatch, getStore) => {
    dispatch(saveMusicStart());

    let accountId = getStore().auth.account.id;
    let body = getStore().music.music;

    const musicRequest = new Request(getStore().auth.token);
    let requestFunc = musicRequest.post;
    let path = PATHS.music.create(accountId);
    if(body.id) {
        requestFunc = musicRequest.put;
        path = PATHS.music.save(accountId, body.id);
    }

    return requestFunc(path, [body])
        .then((response) => {
            dispatch(saveMusicSuccess());
            let musicList = getStore().music.musicList;
            if(body.id) {
                for(var i = 0; i < musicList.length; i++) {
                    if(musicList[i].id === body.id) {
                        musicList[i] = body;
                    }
                }
            } else {
                musicList.push(response.data.data[0]);
            }
            dispatch(setMusicList(musicList));
            return response;
        })
        .catch((err) => {
            // console.log('saveMusic error', err, err.response);
            err.friendlyMessage = 'Error saving your music. Please try again.';
            dispatch(saveMusicFailure(err));
            throw err;
        });
};
