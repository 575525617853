import './VerifyEmail.scss';

import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { resendVerificationEmail, verifyEmail } from 'containers/Auth/store/actions';

import ActivityIndicator from 'components/ActivityIndicator';
import Alert from 'components/Alert';
import Button from 'components/Button';
import H2 from 'components/H2';
import ProgressBar from 'components/ProgressBar';

class VerifyEmail extends React.Component {
    constructor(props) {
        super(props);

        let values = queryString.parse(this.props.location.search);

        this.state = {
            verificationSuccess: false,
            verificationToken: values.t
        };
    }

    componentDidMount() {
        document.body.classList.add('auth-page');
        document.body.classList.add('signup-page');
        document.body.classList.add('verify-email-page');

        if(this.state.verificationToken) {
            this.props.verifyEmail(this.state.verificationToken)
                .then((res) => {
                    this.setState({verificationSuccess: true});
                    setTimeout(() => this.props.history.push(`/plan/${this.props.auth.accounts[0].id}`), 2000);
                })
                .catch((err) => {
                    this.setState({verificationSuccess: true});
                });
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('auth-page');
        document.body.classList.remove('signup-page');
        document.body.classList.remove('verify-email-page');
    }

    render() {
        let { accounts, profile } = this.props.auth;

        return (
            <div className="signup-form--wrapper auth-form--wrapper">
                {this.state.verificationToken == null ? (
                    <div className="signup-form__content auth-form__content">
                        <ProgressBar
                            className="verify-email__progress-bar"
                            step={3}
                            totalSteps={accounts[0] && accounts[0].ownerUserId === profile.id ? 4 : 3}
                        />

                        <div className="verify-email__content">
                            <img src="/img/verify-email-icon.svg" alt="Verify your email" />

                            <H2>
                                Great! We've sent a verification email.
                            </H2>

                            <p>
                                Check the inbox of the email address you used to create your account. Select the “Verify My Email” link to confirm and access your account.
                            </p>

                            {this.props.auth.signupData.user && this.props.auth.signupData.user.email ? (
                                <p>
                                    Didn’t get an email? <Button onClick={() => this.props.resendVerificationEmail()} showActivityIndicator={this.props.auth.isResendingVerificationEmail} theme="link">Resend verification email</Button>
                                </p>
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <div className="signup-form__content auth-form__content">
                        <div className="verify-email__content">
                            <img src="/img/verify-email-icon.svg" alt="Verify your email" />

                            <H2>
                                Verifying your email address
                            </H2>

                            {this.state.verificationSuccess === false ? (
                                <ActivityIndicator />
                            ) : (
                                <React.Fragment>
                                    {this.props.auth.verifyEmailError && Object.keys(this.props.auth.verifyEmailError).length > 0 ? (
                                        <Alert color="red">
                                            {this.props.auth.verifyEmailError.friendlyMessage || this.props.auth.verifyEmailError.message}
                                        </Alert>
                                    ) : (
                                        <React.Fragment>
                                            <p>
                                                Success! Logging you in now.
                                            </p>

                                            <ActivityIndicator />
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resendVerificationEmail: (email) => dispatch(resendVerificationEmail(email)),
        verifyEmail: (token) => dispatch(verifyEmail(token))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
