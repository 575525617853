import './Stuff.scss';

import React from 'react';
import { connect } from "react-redux";
import clone from "clone";
import update from "immutability-helper";

import { handleTextChange } from 'utils/handle-changes';

import { clearPossession, deletePossession, savePossession, saveStuff, setPossession } from 'containers/Stuff/store/actions';
import { setStory } from 'containers/Story/store/actions';

import Button from 'components/Button';
import CreateEditPossessionModal from './CreateEditPossessionModal';
import Form from 'components/Form';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import FormValidationMessage from 'components/FormValidationMessage';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Modal from 'components/Modal';
import Table from 'components/Table';
import Textbox from 'components/Textbox';

class Stuff extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirmDeleteModal: false,
            showEditModal: false,
            showSuccessModal: false,
            startingData: null
        };

        this.possessionsRef = React.createRef();
    }

    componentDidMount() {
        document.body.classList.add('stuff-page');

        this.setState({startingData: clone(this.props.story.story)});

        if(this.possessionsRef && this.possessionsRef.current && window.location.href.includes('/possessions')) {
            this.possessionsRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        } else {
            // console.log(document.getElementsByTagName('body')[0])
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('stuff-page');
    }

    finishDeletePossession = () => {
        this.props.deletePossession(this.props.stuff.possession.id)
            .then(() => {
                this.setState({showConfirmDeleteModal: false});
            });
    };

    handleBack = () => {
        this.props.setStory(this.state.startingData);
        this.props.history.push(`/story/${this.props.account.id}`)
    };

    handleChange = (name, value) => {
        let newStoryObj = update(this.props.story.story, {});

        if(newStoryObj.stuff == null) {
            newStoryObj.stuff = {};
        }

        newStoryObj.stuff[name] = value;

        this.props.setStory(newStoryObj);
    };

    handleDelete = (possession) => {
        this.props.setPossession(possession);
        this.setState({showConfirmDeleteModal: true});
    };

    handleEdit = (possession) => {
        this.props.setPossession(possession);
        this.setState({showEditModal: true});
    };

    handleNew = () => {
        this.setState({showEditModal: true});
    };

    handleSaveStuff = () => {
        this.props.saveStuff()
            .then(() => {
                this.setState({showSuccessModal: true});
                setTimeout(() => this.setState({startingData: clone(this.props.story.story)}), 500);
            });
    };

    render() {
        let stuff = this.props.story.story.stuff || {};
        let { isSaving, possessions, saveError } = this.props.stuff;
        let account = this.props.account;


        let columns = [
            {
                Header: 'What',
                id: 'what',
                accessor: 'what',
                minWidth: 25
            }, {
                Header: 'Who',
                id: 'who',
                accessor: 'who',
                minWidth: 25
            }, {
                Header: 'The story behind it (if applicable):',
                id: 'story',
                accessor: 'story',
                minWidth: 50,
                Cell: (props) => (
                    <div className="letters__body">
                        {props.original.story.split(/\r\n|\r|\n/).map((p) => {
                            if(p) {
                                return <p>{p}</p>;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )
            }, {
                Header: '',
                id: 'actions',
                className: 'letters__actions',
                Cell: (props) => (
                    <React.Fragment>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleEdit(props.original)}>
                            <Icon type="edit" />
                        </Button>
                        <Button className="letters__action-button"
                                noStyles
                                onClick={() => this.handleDelete(props.original)}>
                            <Icon type="delete" />
                        </Button>
                    </React.Fragment>
                ),
                width: 80,
                sortable: false
            }
        ];

        return (
            <div className="stuff" ref={this.topRef}>
                <div className="plan-form__links">
                    <Button className="plan-form__back-link"
                            noStyles
                            onClick={this.handleBack}>
                        <Icon type="chevron-left" /> Back
                    </Button>
                </div>

                <header className="story-form__header">
                    <H3>
                        <img src={`/img/page-icons/stuff.svg`} alt="Other Important Stuff" /> Other Important Stuff
                    </H3>
                </header>

                <div className="plan-form__content">
                    <p>Bank account institutions, investments, where your will is located, deeds to vehicles and other information is vital in making sure loved ones know how to manage your affairs without a lot of headaches and worry.</p>
                    <p>This information will be vital to your family in dealing with your affairs after you're gone.</p>
                    <p>Please don't include any account numbers, usernames, passwords, or other sensitive information. For that, the Lockbox feature coming soon!</p>
                </div>

                <Form className="stuff-form">
                    <div className="plan-form--inner">
                        <FormGroup>
                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtBanking"
                                    instructions="Checking and savings accounts locations"
                                    label="Banking Institutions"
                                    name="banking"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.banking || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtInsurance"
                                    instructions="Health, home owner, life, car, etc."
                                    label="Insurance Information"
                                    name="insuranceInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.insuranceInformation || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMortgage"
                                    instructions="All owned real estate"
                                    label="Mortgage Information"
                                    name="mortgageInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.mortgageInformation || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtVehicle"
                                    instructions="Cars, boats, snowmobiles, etc."
                                    label="Vehicle Information"
                                    name="vehicleInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.vehicleInformation || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtInvestment"
                                    instructions="401k, IRA, stocks, etc."
                                    label="Investment Information"
                                    name="investmentInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.investmentInformation || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtClub"
                                    label="Club Membership Information"
                                    name="clubInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.clubInformation || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtSafetyDepositBox"
                                    instructions="Where it is and where the key is"
                                    label="Safety Deposit Box Information"
                                    name="depositBoxInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.depositBoxInformation || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtUtility"
                                    instructions="Heat, telephone, water, etc."
                                    label="Utility Information"
                                    name="utilityInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.utilityInformation || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtWill"
                                    instructions="Location, attorney, etc."
                                    label="Information Regarding Your Will"
                                    name="willInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.willInformation || ''}
                                />
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={account.myRole === 'member'}
                                    id="txtMisc"
                                    label="Miscellaneous Financial Information"
                                    name="miscFinancialInformation"
                                    onChange={(event) => handleTextChange(this.handleChange, event)}
                                    value={stuff.miscFinancialInformation || ''}
                                />
                            </FormRow>
                        </FormGroup>

                        {saveError && Object.keys(saveError).length > 0 ? (
                            <FormGroup>
                                <FormRow className="error-message">
                                    <FormValidationMessage>
                                        {saveError.friendlyMessage || saveError.message}
                                    </FormValidationMessage>
                                </FormRow>
                            </FormGroup>
                        ) : null}
                    </div>

                    <FormRow>
                        <Button disabled={isSaving || account.myRole === 'member'}
                                onClick={this.handleSaveStuff}
                                showActivityIndicator={isSaving}>
                            Save
                        </Button>
                        <Button className="plan-form__cancel"
                                disabled={isSaving || account.myRole === 'member'}
                                noStyles
                                onClick={this.handleBack}>
                            Cancel
                        </Button>
                    </FormRow>
                </Form>

                {account.myRole === 'admin' ? (
                    <React.Fragment>
                        <header className="story-form__header letters__header my-possessions-header" id="possessions" ref={this.possessionsRef}>
                            <H3>
                                My Possessions
                            </H3>

                            <Button onClick={this.handleNew}
                                    theme="secondary">
                                Add
                            </Button>
                        </header>

                        <div className="plan-form__content">
                            There are many things we accumulate throughout our lives; some that are heirlooms, some that are filled with memories. Who you want to receive your possessions and the story behind them are important things for people to know. Here is the place to share that story, pass along to a person of your choosing and keep the memory of that item alive.
                        </div>

                        <div className="plan-form--inner letters--inner">
                            <FormGroup>
                                <FormRow>
                                    <Label>To whom do you want your special things to go?</Label>
                                </FormRow>

                                <FormRow>
                                    {possessions && possessions.length > 0 ? (
                                        <Table
                                            columns={columns}
                                            data={possessions}
                                            defaultSorted={[{
                                                id: 'what',
                                                desc: false
                                            }]}
                                            pageSize={possessions.length}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            <Button theme="link" onClick={this.handleNew}>Add</Button>
                                        </React.Fragment>
                                    )}
                                </FormRow>
                            </FormGroup>
                        </div>
                    </React.Fragment>
                ) : null}

                <CreateEditPossessionModal
                    closeModal={() => {
                        this.setState({showEditModal: false});
                        this.props.clearPossession();
                    }}
                    error={this.props.stuff.savePossessionError}
                    isSavingPossession={this.props.stuff.isSavingPossession}
                    possession={this.props.stuff.possession}
                    savePossession={this.props.savePossession}
                    setPossession={this.props.setPossession}
                    show={this.state.showEditModal}
                />

                <Modal confirmButtonOnClick={() => {
                           this.finishDeletePossession();
                       }}
                       confirmButtonText="Delete"
                       declineButtonOnClick={() => {
                           this.setState({showConfirmModal: false});
                           this.props.clearPossession();
                       }}
                       declineButtonText="Cancel"
                       show={this.state.showConfirmDeleteModal}
                       showActivityIndicator={this.props.stuff.isDeletingPossession}
                       title="Are you sure you want to remove this possession?">
                    This action cannot be undone.
                </Modal>

                <Modal confirmButtonOnClick={() => {
                           this.setState({showSuccessModal: false});
                           this.props.history.push(`/story/${account.id}`);
                       }}
                       confirmButtonText="OK"
                       declineButtonOnClick={() => {
                           this.setState({showSuccessModal: false});
                           this.props.history.push(`/story/${account.id}`);
                       }}
                       show={this.state.showSuccessModal}
                       title="Success!">
                    Your changes have been saved.
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.auth.account,
        story: state.story,
        stuff: state.stuff
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearPossession: () => dispatch(clearPossession()),
        deletePossession: (possessionId) => dispatch(deletePossession(possessionId)),
        savePossession: () => dispatch(savePossession()),
        saveStuff: () => dispatch(saveStuff()),
        setPossession: (possessionObj) => dispatch(setPossession(possessionObj)),
        setStory: (storyObj) => dispatch(setStory(storyObj))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Stuff);
