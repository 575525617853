import React from 'react';
import { isFormValid, validated } from 'react-custom-validation';
import PropTypes from 'prop-types';
import update from "immutability-helper";
import { isEmail, isRequired } from 'utils/validations';
import { handleTextChange } from 'utils/handle-changes';
import Button from 'components/Button';
import Form from 'components/Form';
import FormRow from 'components/FormRow';
import H2 from 'components/H2';
import Radio from 'components/Radio';
import RadioGroup from 'components/RadioGroup';
import Textbox from 'components/Textbox';

class NewAccountForm extends React.Component {
    static propTypes = {
        accounts: PropTypes.array.isRequired,
        email: PropTypes.string.isRequired,
        isSaving: PropTypes.bool,
        // hasPromoCode: PropTypes.bool,
        navigateTo: PropTypes.func.isRequired,
        newAccountData: PropTypes.object.isRequired,
        // planLevel: PropTypes.object,
        // promoCode: PropTypes.string,
        history: PropTypes.func.isRequired,
        saveNewAccount: PropTypes.func.isRequired,
        setNewAccountData: PropTypes.func.isRequired,
        // submitPromoCode: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            // hasPlan: props.accounts.filter((a) => a.email === this.props.email).length > 0,
            // isSubmittingPayment: false,
            // stripeError: null
        };
    }

    componentDidMount() {
        // console.log('NewAccountForm.componentDidMount');
        if(this.state.hasPlan) {
            this.handleChange('for', 'someoneElse');
        }
    }

    handleChange = (name, value) => {
        let newAccountObj = update(this.props.newAccountData, {});
        newAccountObj[name] = value;
        this.props.setNewAccountData(newAccountObj);
    };

    handleSubmit = async (isValid) => {
        if (isValid) {
            try {
                this.setState({isSaving: true});
                let res = await this.props.saveNewAccount();
                this.setState({isSaving: false});
                // this.props.navigateTo(`/plan/${res.data.data.id}`);
                this.props.history.push(`/plan/${res.data.data.id}`);
            } catch (err) {
                // console.log(err, err.response);
                this.setState({isSaving: false});
            }
        }
    }

    // handleSubmit = async (isValid) => {
    //     if(this.state.isSubmittingPayment) {
    //         return;
    //     }
    //
    //     this.setState({stripeError: null, isSubmittingPayment: true});
    //
    //     if(isValid) {
    //         if(this.props.hasPromoCode) {
    //             this.props.submitPromoCode();
    //         } else {
    //             try {
    //                 let stripeRes = await this.props.stripe.createToken();
    //
    //                 if(stripeRes.error) {
    //                     this.setState({stripeError: stripeRes.error.message});
    //                     return;
    //                 }
    //                 let res = await this.props.saveNewAccount(stripeRes.token.id, this.props.planLevel.plan);
    //                 this.setState({isSubmittingPayment: false});
    //                 this.props.navigateTo(`/plan/${res.data.data.id}`);
    //             } catch (err) {
    //                 console.log(err, err.response);
    //                 this.setState({isSubmittingPayment: false});
    //             }
    //         }
    //     }
    // };

    render() {
        // console.log('NewAccountForm.render');
        let { newAccountData, $field, $validation } = this.props;

        return (
            <Form className="signup-form signup-name-form auth-form">
                <H2>
                    Welcome! Let's get started together.
                </H2>

                <p>
                    Are you creating a new plan for yourself or to help someone else?
                </p>

                <div className="new-account-form__fields">
                    <FormRow>
                        <RadioGroup horizontal>
                            <Radio
                                disabled={this.state.hasPlan}
                                label="For me"
                                checked={newAccountData.for === 'me'}
                                id="rdoForMe"
                                name="for"
                                onChange={() => this.handleChange('for', 'me')}
                                value="me"
                            />

                            <Radio
                                disabled={this.state.hasPlan}
                                label="For someone else"
                                checked={newAccountData.for === 'someoneElse'}
                                id="rdoForSomeoneElse"
                                name="for"
                                onChange={() => this.handleChange('for', 'someoneElse')}
                                value="someoneElse"
                            />
                        </RadioGroup>
                    </FormRow>

                    <FormRow>
                        <Textbox
                            id="txtFirstName"
                            label="First Name"
                            name="firstName"
                            required
                            type="text"
                            validation={$validation.firstName}
                            value={newAccountData.firstName || ''}
                            {...$field('firstName', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>

                    <FormRow>
                        <Textbox
                            id="txtLastName"
                            label="Last Name"
                            name="lastName"
                            required
                            type="text"
                            validation={$validation.lastName}
                            value={newAccountData.lastName || ''}
                            {...$field('lastName', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>

                    <FormRow>
                        <Textbox
                            id="txtEmail"
                            label="Email Address"
                            name="email"
                            required
                            type="email"
                            validation={$validation.email}
                            value={newAccountData.email || ''}
                            {...$field('email', (event) => handleTextChange(this.handleChange, event))}
                        />
                    </FormRow>

                    {/*{this.state.stripeError ? (*/}
                    {/*    <FormRow className="error-message">*/}
                    {/*        <FormValidationMessage>*/}
                    {/*            {this.state.stripeError}*/}
                    {/*        </FormValidationMessage>*/}
                    {/*    </FormRow>*/}
                    {/*) : null}*/}
                </div>

                <FormRow className="signup-form__button-wrapper">
                    <Button disabled={isFormValid($validation) !== true || this.props.isSaving}
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.$submit(() => this.handleSubmit(true), () => this.handleSubmit(false));
                            }}
                            showActivityIndicator={this.props.isSaving}
                            type="submit">
                        Next
                    </Button>
                </FormRow>
            </Form>
        )
    }
}

function newAccountFormValidationConfig(props) {
    const { email, firstName, lastName } = props.newAccountData;

    return {
        fields: ['email', 'firstName', 'lastName'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ],
            firstName: [
                [isRequired, firstName]
            ],
            lastName: [
                [isRequired, lastName]
            ]
        }
    }
}

export default NewAccountForm = validated(newAccountFormValidationConfig)(NewAccountForm);
